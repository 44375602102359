import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  businessRequestApi,
  useTakeRequestToWorkMutation,
} from 'src/api/business-request/business-request';
import { Button, ConfirmDialog, Typography } from 'src/components/ui';
import { IContractorBusinessRequest } from 'src/models';
import { getErrorMessage } from 'src/store/utils';
import { useAppDispatch } from 'src/store';
import { formatServerDate } from 'src/utils';
import { isExpiredCommercialOfferDueDate } from 'src/utils/business-request';
import { AddIcon } from 'src/assets/icons';

interface ITakeWorkProps {
  businessRequest: IContractorBusinessRequest;
}

export const TakeWorkBusinessRequest: FC<ITakeWorkProps> = ({
  businessRequest,
}) => {
  const dispatch = useAppDispatch();
  const [setContractor, { isLoading, error }] = useTakeRequestToWorkMutation();
  const navigate = useNavigate();

  const [modalConfirmation, setModalConfirmation] = useState(false);

  const toggleModalConfirmation = () => {
    setModalConfirmation((prevState) => !prevState);
  };

  const handleSubmit = async () => {
    try {
      const result = await setContractor(
        businessRequest.businessRequestId
      ).unwrap();

      toggleModalConfirmation();

      dispatch(
        businessRequestApi.util.updateQueryData(
          'getContractorBusinessRequest',
          businessRequest.businessRequestId,
          (cache) => ({ ...cache, contractorRequestId: result.id })
        )
      );

      navigate(`/requests/${result.id}`);
    } catch {
      // ignore
    }
  };

  if (
    businessRequest.contractorRequestId ||
    businessRequest?.statusHistory?.status?.code !== 'NEW_REQUEST' ||
    isExpiredCommercialOfferDueDate(businessRequest.commercialOfferDueDate)
  ) {
    return null;
  }

  return (
    <>
      <Button
        variant="text"
        startIcon={<AddIcon />}
        color="success"
        onClick={toggleModalConfirmation}>
        Взять в проработку
      </Button>

      <ConfirmDialog
        open={modalConfirmation}
        close={toggleModalConfirmation}
        title="Вы уверены, что хотите взять запрос в проработку?"
        confirmText="Да, уверен"
        settingsBtn={{ loading: isLoading }}
        maxWidth={600}
        onConfirm={handleSubmit}>
        <Typography variant="h4">
          {`КП необходимо отправить в срок до ${formatServerDate(
            new Date(businessRequest.commercialOfferDueDate),
            'dd.MM.yyyy'
          )}`}
        </Typography>
        {error && (
          <Typography color="error" marginTop="10px">
            {getErrorMessage(
              error,
              'Произошла ошибка при взятии запроса в проработку'
            )}
          </Typography>
        )}
      </ConfirmDialog>
    </>
  );
};
