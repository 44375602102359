import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiChip: Components<Theme>['MuiChip'] = {
  styleOverrides: {
    root: {
      borderRadius: '8px',
      marginRight: '8px',
    },
    avatar: {
      width: 'unset',
      height: 'unset',
    },
    label: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    filledSecondary: ({ theme }) => ({
      border: '1px solid',
      borderColor: theme.palette.customTheme.borderGrayPrimary,
      backgroundColor: theme.palette.customTheme.overlayBlueDefault,
      marginRight: 0,
    }),
  },
};
