import { FC, ReactNode } from 'react';
import { Tooltip, Typography } from 'src/components/ui';
import styles from './notification-dot.module.scss';

type TNotificationDotProps = {
  message?: string;
  children: ReactNode;
  isShown?: boolean;
  counter?: number;
};

export const NotificationDot: FC<TNotificationDotProps> = ({
  message,
  children,
  isShown = false,
  counter,
}) => (
  <div className={styles.wrapper}>
    {children}
    {isShown && (
      <Tooltip title={message} placement="top-end">
        <div className={styles.redDot} />
      </Tooltip>
    )}
    {!!counter && (
      <span className={styles.counterWrapper}>
        <Typography fontSize={12} color="#0041A0">
          {counter}
        </Typography>
      </span>
    )}
  </div>
);
