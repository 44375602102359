import { Components } from '@mui/material/styles/components';

export const MuiAutocomplete: Components['MuiAutocomplete'] = {
  defaultProps: {
    noOptionsText: 'Список пуст',
  },
  styleOverrides: {
    root: {
      '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
        padding: 0,
        paddingLeft: 14,
      },
    },
    inputRoot: {
      padding: 0,
    },
  },
};
