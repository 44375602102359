import { FC, useState } from 'react';
import {
  Dialog,
  Button,
  Typography,
  DialogActions,
  DialogContent,
  DialogTitle,
} from 'src/components/ui';
import { useRemoveBusinessApplicationMutation } from 'src/api/business-request/business-request';
import { TVariantBusinessRequest } from 'src/models';

import { RedTrash } from 'src/assets/icons';
import { getErrorMessage } from 'src/store/utils';

import { useNavigate } from 'react-router-dom';
import styles from './remove-request.module.scss';

export const RemoveRequest: FC<{
  businessRequest: TVariantBusinessRequest;
}> = ({ businessRequest }) => {
  const navigate = useNavigate();
  const [showDialog, setShowDialog] = useState<boolean>(false);
  const [removeBusinessApplication, { isLoading, error }] =
    useRemoveBusinessApplicationMutation();

  const handleClose = () => {
    setShowDialog(false);
  };

  const handleRemove = async () => {
    try {
      await removeBusinessApplication({
        businessApplicationId: businessRequest.businessApplicationId!,
        businessRequestId: businessRequest.businessRequestId!,
      }).unwrap();
      handleClose();
      navigate('/requests');
    } catch {
      // ignore
    }
  };

  return (
    <>
      <Button
        variant="text"
        color="error"
        className={styles.restoreButton}
        startIcon={<RedTrash />}
        onClick={() => setShowDialog(true)}>
        Удалить
      </Button>

      <Dialog
        PaperProps={{
          sx: {
            width: '600px',
          },
        }}
        open={showDialog}
        close={handleClose}>
        <DialogTitle>Вы уверены, что хотите удалить заявку?</DialogTitle>

        <DialogContent>
          <Typography variant="h4" mb="24px">
            Сразу после подтверждения заявка будет удалена
          </Typography>
          {error && (
            <Typography color="error">
              {getErrorMessage(error, 'Произошла ошибка при удалении заявки')}
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          <Button loading={isLoading} onClick={handleRemove}>
            Удалить заявку
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
