import { FC } from 'react';
import { DialogProps } from '@mui/material/Dialog/Dialog';
import { Dialog as MuiDialog } from '@mui/material';
import { IconButton } from 'src/components/ui';
import { CloseIcon } from 'src/assets/icons';

export type TDialogProps = DialogProps & {
  close?: () => void;
  width?: string;
};

export const Dialog: FC<TDialogProps> = ({
  close,
  width = 'auto',
  children,
  ...props
}) => (
  <MuiDialog
    PaperProps={{
      sx: {
        width,
      },
    }}
    {...props}>
    {close && (
      <IconButton
        aria-label="close"
        onClick={close}
        sx={{
          position: 'absolute',
          right: { xs: 22, sm: 28 },
          top: { xs: 24, sm: 44 },
        }}>
        <CloseIcon />
      </IconButton>
    )}
    {children}
  </MuiDialog>
);
