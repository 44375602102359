import { Components } from '@mui/material/styles/components';
import { Theme } from '@mui/material';

export const MuiButtonGroup: Components<Theme>['MuiButtonGroup'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      background: 'rgba(0, 47, 108, 0.06)',
      borderRadius: '8px',
      display: 'inline-block',
      padding: '4px',
      '& .MuiButtonGroup-grouped, & .MuiButtonGroup-grouped:hover': {
        borderColor: 'transparent',
        color: theme.palette.text.secondary,
        padding: '8px 16px',
        borderRadius: '4px',
        '&:not(:first-of-type), &:not(:last-of-type)': {
          borderColor: 'transparent',
          borderRadius: '4px',
        },
        '&.active': {
          backgroundColor: '#ffffff',
          color: theme.palette.text.primary,
          fontWeight: 500,
        },
      },
    }),
  },
};
