import { Key } from 'react';
import { TableCell } from '@mui/material';
import { ColumnType } from 'src/components/ui/new-table/types';

import styles from './cell.module.scss';

interface CellType<RecordType> {
  key: Key | keyof RecordType;
  record: RecordType;
  column: ColumnType<RecordType>;
  index: number;
}

export const Cell = <RecordType,>({
  column,
  index,
  record,
}: CellType<RecordType>) => {
  const data = record[column.key] as string;
  const Component = column.render && column.render(record, index);

  return (
    <TableCell {...column.setting} className={styles.cell}>
      {Component || data}
    </TableCell>
  );
};
