import { Components } from '@mui/material/styles/components';
import { RadioEllipsisUnchecked, RadioEllipsisChecked } from 'src/assets/icons';

export const MuiRadio: Components['MuiRadio'] = {
  defaultProps: {
    disableRipple: true,
    icon: <RadioEllipsisUnchecked />,
    checkedIcon: <RadioEllipsisChecked />,
  },
  styleOverrides: {
    root: {
      padding: '0',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
};
