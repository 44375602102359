import { FC } from 'react';
import styles from './communication-date-panel.module.scss';

interface ICommunicationDatePanelProps {
  date: string;
}

export const CommunicationDatePanel: FC<ICommunicationDatePanelProps> = ({
  date,
}) => <span className={styles.wrapper}>{date}</span>;
