import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useGetCustomerInfoQuery } from 'src/api/business-request/business-request';
import {
  useGetCompanyHasActiveRequestsQuery,
  useLazyGetActiveOrganizationsByTypeQuery,
  useLazyGetByIdQuery,
} from 'src/api/company';
import {
  useAddContactMutation,
  useGetContactsQuery,
  useRebaseContactToAnotherCompanyMutation,
  useUpdateContactMutation,
} from 'src/api/contacts';
import { useBusinessRequestCustomerInfoContext } from 'src/components/layout/bids-layout/hooks';
import { useCompanyContext } from 'src/components/layout/company-layout';
import { ChangeMainContactDialog } from 'src/components/pages/company/contacts/components/add-item/components';
import { useCheckIsContactPrimary } from 'src/components/pages/company/contacts/components/add-item/hooks';
import {
  TContact,
  TContactInfo,
  TFormInput,
} from 'src/components/pages/company/contacts/types';
import {
  Autocomplete,
  Box,
  Checkbox,
  ConfirmDialog,
  Form,
  Result,
  Typography,
} from 'src/components/ui';
import { UserProfileFields } from 'src/components/widgets/user-profile';
import { ROLES } from 'src/constants';
import { CompanyTypes, ICompany, TContactRequest } from 'src/models';
import { TUpdateContact } from 'src/models/contacts';
import { AuthService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { required } from 'src/utils/form-rules';

type TProps = {
  onSave?: () => void;
  onCancel?: () => void;
  defaultItem?: TContact;
  companyTypeDefined: CompanyTypes;
  companyId?: number;
};

export const AddItem: FC<TProps> = ({
  onSave,
  onCancel,
  defaultItem,
  companyTypeDefined,
  companyId,
}) => {
  // Данные необходимые для заполнения поля "Компания" у контакта
  const [currentContactCompanyData, setCurrentContactCompanyData] = useState<
    ICompany | undefined
  >(undefined);

  const [isContactPrimary, isPrimaryInfoLoading] = useCheckIsContactPrimary(
    defaultItem,
    currentContactCompanyData,
    companyTypeDefined
  );

  const otherRole =
    companyTypeDefined === CompanyTypes.CUSTOMER ? 'исполнителе' : 'заказчике';

  const contactCompanyHelperText = isContactPrimary
    ? `Данный контакт является основным в компании ${otherRole}, 
    для изменения компании необходимо выбрать новый основной контакт в компании ${otherRole}`
    : 'У компании есть заявки и только один контакт,' +
      ' для того чтобы изменить компанию, должен быть другой контакт или не быть заявок';

  const [showChooseContactModal, setShowChooseContactModal] = useState(false);

  const canChangeCompany =
    AuthService.currentRole !== ROLES.CUSTOMER &&
    AuthService.currentRole !== ROLES.CONTRACTOR;

  // Не undefined, только когда компонент используется на странице компании, а не заявки
  const { companyData: company, companyType } = useCompanyContext();

  // Не undefined, только когда используется на странице заявки
  const { businessRequest } = useBusinessRequestCustomerInfoContext();
  const { data: customerInfo } = useGetCustomerInfoQuery(
    businessRequest?.businessRequestId ?? -1
  );

  // Эти данные всегда доступны, так как были получены ранее
  const { data: contacts, isLoading: isContactsLoading } = useGetContactsQuery({
    idCompany: company ? company.id : customerInfo!.organizationId,
    companyType: companyType ?? CompanyTypes.CUSTOMER,
  });

  // Todo: пофиксить костыль с ref (значение для autocomplete на форме
  // не обновляется при выборе опции если ранее изменялись другие поля)
  const companyRef = useRef<TContactInfo>();

  const {
    data: existingRequestsInfo,
    isLoading: isExistingRequestsInfoLoading,
    error: isExistingRequestsInfoError,
  } = useGetCompanyHasActiveRequestsQuery(
    {
      organizationId: company ? company.id : customerInfo!.organizationId,
    },
    { skip: !defaultItem }
  );

  const showHelperText =
    isContactPrimary ||
    (!!contacts &&
      contacts.length < 2 &&
      (existingRequestsInfo?.customerOrganizationRoleHasActiveApplications ||
        existingRequestsInfo?.contractorOrganizationRoleHasActiveApplications));

  const [getOrganizationData] = useLazyGetByIdQuery(CompanyTypes.CUSTOMER)();

  const [showMainContactFormConfirm, setShowMainContactFromConfirm] =
    useState<boolean>(false);
  const [addContact, { isLoading }] = useAddContactMutation();
  const [updateContactById, { isLoading: isUpdating }] =
    useUpdateContactMutation();

  const [getActiveOrganizationsByType] =
    useLazyGetActiveOrganizationsByTypeQuery();

  const [rebaseContactToAnotherCompany, { isLoading: isRebaseLoading }] =
    useRebaseContactToAnotherCompanyMutation();

  const isEdit = !!defaultItem;

  const form = useForm<TFormInput>({
    defaultValues: {
      login: defaultItem?.email || '',
      lastName: defaultItem?.lastName || '',
      firstName: defaultItem?.firstName || '',
      middleName: defaultItem?.middleName || '',
      position: defaultItem?.position || '',
      contactInfoDto: defaultItem?.contactInfoDto || {
        phones: [
          {
            type: 'MOBILE',
            number: '',
          },
        ],
      },
      isMainContact: defaultItem?.isMainContact || false,
      isSendEmail:
        (defaultItem?.notificationChannels?.length &&
          defaultItem.notificationChannels.includes('EMAIL')) ||
        false,
      contactCompany: {
        id: currentContactCompanyData?.id,
        organizationName:
          currentContactCompanyData?.organizationDetails?.organizationName,
        inn: currentContactCompanyData?.inn,
        ogrn: currentContactCompanyData?.organizationDetails?.ogrn,
      },
    },
  });

  useEffect(() => {
    if (currentContactCompanyData) {
      form.setValue('contactCompany', {
        id: currentContactCompanyData.id,
        organizationName:
          currentContactCompanyData?.organizationDetails?.organizationName,
        inn: currentContactCompanyData?.inn,
        ogrn: currentContactCompanyData?.organizationDetails?.ogrn,
      });
    }
  }, [currentContactCompanyData]);

  const rebaseContact = async (
    sourceOrganizationId: number,
    contactId: number,
    targetOrganizationId: number,
    newMainContactId?: number
  ) => {
    try {
      await rebaseContactToAnotherCompany({
        sourceOrganizationId,
        contactId,
        targetOrganizationId,
        newMainContactId,
      });
      onSave?.();
    } catch (error) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          error,
          'Произошла ошибка при смене основного контакта'
        ),
      });
    }
  };

  // Получение данных организации контакта со страницы запроса
  const getContactOrganizationData = async () => {
    const organizationData = await getOrganizationData(
      customerInfo!.organizationId
    ).unwrap();
    companyRef.current = organizationData;
    setCurrentContactCompanyData(organizationData);
  };

  useEffect(() => {
    if (isEdit) {
      if (company) {
        companyRef.current = company;
        setCurrentContactCompanyData(company);
      } else {
        getContactOrganizationData();
      }
    }
  }, [currentContactCompanyData]);

  const onSubmit: SubmitHandler<TFormInput> = async (newItem) => {
    const { login, isMainContact, isSendEmail } = newItem;

    let currentCompanyType: TContactRequest['contactType'];

    if (companyTypeDefined) {
      currentCompanyType = isMainContact
        ? `MAIN_${companyTypeDefined}`
        : `PLAIN_${companyTypeDefined}`;
    } else {
      currentCompanyType = isMainContact
        ? `MAIN_${companyType}`
        : `PLAIN_${companyType}`;
    }

    const body: TContactRequest | TUpdateContact = {
      amoCrmId: null,
      login,
      lastName: newItem.lastName,
      firstName: newItem.firstName,
      middleName: newItem.middleName || '',
      position: newItem.position,
      contactType: currentCompanyType,
      businessApplicationId: businessRequest?.businessApplicationId,
      ...(isEdit
        ? {
            notificationChannels: isSendEmail ? ['EMAIL'] : [],
            contactInfoDto: newItem.contactInfoDto,
            contactCompany: newItem.contactCompany,
          }
        : {
            notificationChannel: isSendEmail ? 'EMAIL' : null,
            organizationId: company?.id || companyId!,
            contactInfo: newItem.contactInfoDto,
          }),
    };
    try {
      if ('notificationChannels' in body) {
        if (isEdit) {
          await updateContactById({
            body,
            contactId: defaultItem.id,
            shouldInvalidate:
              companyRef.current?.id === currentContactCompanyData?.id,
          }).unwrap();
          if (
            contacts!.length > 2 &&
            defaultItem.isMainContact &&
            companyRef.current?.id !== currentContactCompanyData?.id &&
            !showChooseContactModal
          ) {
            setShowChooseContactModal(true);
            return;
          }

          if (companyRef.current?.id !== currentContactCompanyData?.id) {
            const newMainUser = contacts?.filter(
              (contact) => contact.id !== defaultItem.id
            )[0];

            await rebaseContact(
              currentContactCompanyData!.id,
              defaultItem.id,
              companyRef.current!.id,
              newMainUser?.id
            );
            return;
          }

          onSave?.();
        }
      } else {
        await addContact({ ...body }).unwrap();
        onSave?.();
      }
    } catch (e) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(e, 'Произошла ошибка при создании контакта'),
      });
    }
  };

  const setMainContact = (checkValue: boolean) => {
    form.setValue('isMainContact', checkValue, { shouldDirty: true });
    setShowMainContactFromConfirm(false);
  };

  const handleChangeMainContact = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setShowMainContactFromConfirm(true);
      form.resetField('isMainContact');
    }
  };

  // TODO: Навести порядок (companyType, companyTypeDefined и тд)
  const fetchOrganizations = useCallback(
    (companyData: string) =>
      getActiveOrganizationsByType({
        organizationRole: companyTypeDefined,
        searchQuery: companyData,
      }).unwrap(),
    [getActiveOrganizationsByType]
  );

  if (isExistingRequestsInfoError) {
    return (
      <Result
        title={getErrorMessage(
          isExistingRequestsInfoError,
          'Произошла ошибка при загрузке данных о контактного лица'
        )}
      />
    );
  }

  return (
    <>
      <Form<TFormInput>
        form={form}
        onSubmit={onSubmit}
        onCancel={onCancel}
        loading={
          isLoading ||
          isUpdating ||
          isRebaseLoading ||
          isContactsLoading ||
          (defaultItem && isPrimaryInfoLoading)
        }
        preloading={
          defaultItem &&
          (!currentContactCompanyData || isExistingRequestsInfoLoading)
        }>
        <UserProfileFields isEditMode />

        <Form.Item
          name="isSendEmail"
          viewMode={false}
          isControlLabel
          label="Оповещать по E-mail"
          tooltip="Контактное лицо будет получать уведомления по E-mail по всем сделкам и вопросам компании.">
          <Checkbox />
        </Form.Item>
        <Form.Item
          name="isMainContact"
          viewMode={false}
          isControlLabel
          label="Основной контакт"
          tooltip="Основной контакт будет получать уведомления по всем вопросам, которые связаны с его компанией. В организации может быть только один основной контакт.">
          <Checkbox onChange={handleChangeMainContact} />
        </Form.Item>
        {/* {isEdit && */}
        {/*   currentContactCompanyData && */}
        {/*   existingRequestsInfo && */}
        {/*   canChangeCompany && ( */}
        {/*     <Form.Item */}
        {/*       rules={{ required }} */}
        {/*       name="contactCompany" */}
        {/*       help={showHelperText ? contactCompanyHelperText : ''} */}
        {/*       label="Компания"> */}
        {/*       <Autocomplete */}
        {/*         popupIcon */}
        {/*         disabled={showHelperText} */}
        {/*         onChange={(value) => { */}
        {/*           companyRef.current = value as unknown as TContactInfo; */}
        {/*         }} */}
        {/*         getOptionLabel={(option) => option.organizationName || ''} */}
        {/*         fetchApi={fetchOrganizations} */}
        {/*         renderOption={(props, option) => ( */}
        {/*           <Box component="li" {...props} key={option.id}> */}
        {/*             <div> */}
        {/*               <Typography sx={{ fontWeight: 'bold' }} textAlign="left"> */}
        {/*                 {option.organizationName} */}
        {/*               </Typography> */}
        {/*               <Typography color="secondary"> */}
        {/*                 {`ИНН: ${option.inn || '-'} / ОГРН: ${ */}
        {/*                   option.ogrn || '-' */}
        {/*                 } `} */}
        {/*               </Typography> */}
        {/*             </div> */}
        {/*           </Box> */}
        {/*         )} */}
        {/*       /> */}
        {/*     </Form.Item> */}
        {/*   )} */}
      </Form>

      <ConfirmDialog
        open={showMainContactFormConfirm}
        close={() => setMainContact(false)}
        title="Назначить контакт основным?"
        confirmText="Да, назначить"
        onConfirm={() => setMainContact(true)}>
        Данный контакт станет основным.
      </ConfirmDialog>
      {isEdit && (
        <ChangeMainContactDialog
          open={showChooseContactModal}
          onClose={() => setShowChooseContactModal(false)}
          currentContact={defaultItem!}
          contactsList={contacts ?? []}
          onNewMainContactSubmitted={(newMainContactId) => {
            rebaseContact(
              currentContactCompanyData!.id,
              defaultItem.id,
              companyRef.current!.id,
              newMainContactId
            );
          }}
        />
      )}
    </>
  );
};
