import { ReactElement, useState } from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import { ArrowLong, BackIcon } from 'src/assets/icons';
import {
  MenuItem,
  Typography,
  Stack,
  SvgIcon,
  Drawer,
  Button,
} from 'src/components/ui';
import classNames from 'classnames';
import styles from './aside-menu.module.scss';

interface IAsideMenuProps {
  menuItems: {
    title: string;
    children: {
      title: string;
      path: string;
      icon: ReactElement;
    }[];
  }[];
  isMenuCollapsed: boolean;
  onClick: () => void;
}

export const AsideMenu = ({
  menuItems,
  onClick,
  isMenuCollapsed,
}: IAsideMenuProps) => {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const isTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xl')
  );

  return !isMobile ? (
    <Box
      className={classNames(
        styles.aside,
        isMenuCollapsed && styles.asideCollapsed,
        isTablet && styles.asideTablet
      )}>
      <Stack
        direction="row"
        gap="12px"
        onClick={onClick}
        alignItems="center"
        style={{ cursor: 'pointer' }}>
        <SvgIcon
          icon={BackIcon}
          fill="#292929"
          className={isMenuCollapsed ? styles.toggleButtonCollapsed : ''}
        />
        {!isMenuCollapsed && (
          <Typography
            variant="h2"
            fontSize={16}
            lineHeight="24px"
            minWidth="max-content">
            Настройки системы
          </Typography>
        )}
      </Stack>

      {menuItems.map((item) => (
        <Box key={item.title} className={styles.block}>
          {!isMenuCollapsed && !isTablet && (
            <Typography fontSize={14} fontWeight={500}>
              {item.title}
            </Typography>
          )}
          <Box className={styles.blockContent}>
            {item.children.map((child) => (
              <MenuItem
                key={child.title}
                component={NavLink}
                to={child.path}
                className={styles.menuItem}>
                <Stack direction="row" gap="12px" alignItems="center">
                  {child.icon}
                  {!isMenuCollapsed && child.title}
                </Stack>
              </MenuItem>
            ))}
          </Box>
        </Box>
      ))}
    </Box>
  ) : (
    <Drawer
      open={!isMenuCollapsed}
      close={onClick}
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: '#F3F6FB',
        },
      }}>
      <div className={styles.mobileWrapper}>
        <Button
          sx={{ color: '#000000', padding: '20px' }}
          startIcon={
            <SvgIcon
              icon={ArrowLong}
              strokeColor="#000000"
              className={styles.arrow}
            />
          }
          onClick={onClick}
          variant="text">
          Меню настроек системы
        </Button>

        <Box
          className={classNames(
            styles.aside,
            isMenuCollapsed && styles.asideCollapsed,
            // isSmallDevice && styles.asideSmall
            isMobile && styles.asideMobile
          )}>
          {menuItems.map((item) => (
            <Box key={item.title} className={styles.block} onClick={onClick}>
              <Typography fontSize={14} fontWeight={500}>
                {item.title}
              </Typography>
              <Box className={styles.blockContent}>
                {item.children.map((child) => (
                  <MenuItem
                    key={child.title}
                    component={NavLink}
                    to={child.path}
                    className={styles.menuItem}>
                    <Stack direction="row" gap="12px" alignItems="center">
                      {child.icon}
                      {child.title}
                    </Stack>
                  </MenuItem>
                ))}
              </Box>
            </Box>
          ))}
        </Box>
      </div>
    </Drawer>
  );
};
