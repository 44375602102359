import classNames from 'classnames';
import { CSSProperties, FC, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Arrow } from 'src/assets/icons';
import { Button, Box, ConfirmDialog } from 'src/components/ui';
import { PERMISSIONS, ROLES } from 'src/constants';
import { RolePermissionService, AuthService } from 'src/services';
import { useAppSelector } from 'src/store';
import { selectIsGuestAuth } from 'src/store/auth';
import { useScrollTrigger } from 'src/hooks';
import styles from './button-go-back.module.scss';

const HEADER_HEIGHT = 60;

type TButtonGoBackProps = {
  textModal?: string;
  onClick?: () => void;
  containerStyle?: CSSProperties;
};

export const ButtonGoBack: FC<TButtonGoBackProps> = ({
  textModal,
  onClick,
  containerStyle,
}) => {
  const [open, setOpen] = useState(false);

  const { state, pathname } = useLocation();
  const backUrl = useRef(state);
  const navigate = useNavigate();

  const handleNavigate = () => {
    if (backUrl.current?.path) {
      navigate(backUrl.current.path);
      return;
    }

    if (pathname.includes('companies')) {
      if (
        pathname.includes('customers') &&
        RolePermissionService.can(PERMISSIONS.VIEW_CUSTOMER_LIST)
      ) {
        navigate('/companies/customers');
      } else if (
        pathname.includes('contractors') &&
        RolePermissionService.can(PERMISSIONS.VIEW_CONTRACTOR_LIST)
      ) {
        navigate('/companies/contractors');
      } else {
        navigate('/companies');
      }
      return;
    }

    if (pathname.includes('all-requests')) {
      navigate('/all-requests');
      return;
    }

    if (pathname.includes('requests')) {
      navigate('/requests');
    }
  };

  const handleClickGoBack = () => {
    if (textModal) {
      setOpen(true);
    } else {
      handleNavigate();
    }
  };

  const { isActive } = useScrollTrigger({ triggerValue: 5 });

  return (
    <div
      style={{ top: HEADER_HEIGHT, ...containerStyle }}
      className={classNames(
        styles.container,
        isActive && styles.isShowBackground
      )}>
      <Box width="100%" height="100%" position="relative" display="flex">
        <Button
          onClick={onClick ?? handleClickGoBack}
          sx={{ fontSize: '16px' }}
          variant="text"
          startIcon={<Arrow />}>
          Назад
        </Button>
      </Box>
      {open && (
        <ConfirmDialog
          confirmText="Да"
          title={textModal}
          open={open}
          onConfirm={handleNavigate}
          close={() => setOpen(false)}
        />
      )}
    </div>
  );
};
