import { Theme } from '@mui/material';
import { Components } from '@mui/material/styles/components';

export const MuiFormHelperText: Components<Theme>['MuiFormHelperText'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      fontSize: '12px',
      lineHeight: '20px',
      letterSpacing: 'normal',
      margin: '4px 0 -24px',

      [theme.breakpoints.down('md')]: {
        margin: '4px 0 -18px',
        fontSize: '10px',
        lineHeight: 1.5,
      },
    }),
  },
};
