import { FC } from 'react';
import { FIELD_LENGTH } from 'src/constants';
import { maxLength, required } from 'src/utils/form-rules';
import { Form, InfoRow, OutlinedInput, Typography } from 'src/components/ui';
import { IComponentsProp } from '../../types';

export const PaymentConditions: FC<IComponentsProp> = ({
  viewMode,
  checkRequired,
}) => (
  <Form.Item
    required
    viewMode={viewMode}
    name="paymentConditions"
    label="Условия оплаты"
    rules={{
      required: checkRequired ? required : undefined,
      maxLength: maxLength(FIELD_LENGTH.commercialOffer.paymentConditions),
    }}
    renderView={(value) => (
      <InfoRow
        label="Условия оплаты"
        value={value && <Typography whiteSpace="pre-wrap">{value}</Typography>}
      />
    )}>
    <OutlinedInput
      multiline
      placeholder="Введите условия оплаты"
      maxRows={7}
      maxLength={FIELD_LENGTH.commercialOffer.paymentConditions}
    />
  </Form.Item>
);
