import { FC, useState } from 'react';
import { saveAs } from 'file-saver';
import {
  ConfirmDialog,
  IconButton,
  Loader,
  Typography,
  OverflowTooltip,
  SvgIcon,
} from 'src/components/ui';
import { fileSizeFormat, formatServerDate, joinFullName } from 'src/utils';
import {
  DownloadArrow,
  FileAdd,
  FileDeleted,
  FileError,
  TrashIcon,
} from 'src/assets/icons';
import { TFile } from 'src/components/ui/file-upload';
import { getErrorMessage } from 'src/store/utils';
import classnames from 'classnames';
import styles from './file-item.module.scss';
import { WatchedStatus } from '../watched-status';
import { IFileItemProps } from '../../types';

const renderFileStatus = (file: TFile) => {
  if (file.error) {
    return <FileError />;
  }

  if (file.deleted) {
    return <FileDeleted />;
  }

  return <FileAdd />;
};

export const FileItem: FC<IFileItemProps> = ({
  file,
  onDownload,
  onRemove,
  canRemove,
  removeMode = 'default',
  removeConfirmText = '',
  showDownloadButton = true,
  disabledDeleteButton = false,
  disabledFileItem = false,
  withSignature = true,
}) => {
  const isSoftRemove = removeMode === 'soft';
  const [showRemoveConfirm, setShowRemoveConfirm] = useState<boolean>(false);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const [isRemoving, setIsRemoving] = useState<boolean>(false);
  const isLoading = isDownloading || isRemoving;

  const handleDownload = async () => {
    try {
      setIsDownloading(true);
      await onDownload?.(file);
    } catch {
      // ignore
    } finally {
      setIsDownloading(false);
    }
  };

  const handleRemove = async () => {
    try {
      setIsRemoving(true);
      await onRemove?.(file);
    } catch (e) {
      console.log(e);
    } finally {
      setIsRemoving(false);
    }
  };

  if (file.deleted) {
    return null;
  }

  return (
    <div className={styles.file}>
      <div
        className={classnames(
          styles.fileData,
          disabledFileItem && styles.disabled,
          !withSignature && styles.borderNone
        )}>
        <div className={styles.fileDataIcon}>
          {disabledFileItem ? <FileDeleted /> : renderFileStatus(file)}
        </div>
        <div className={styles.fileDataInfo}>
          <OverflowTooltip text={file.name} color="primary" />
          <Typography color="text.secondary" variant="body2">
            {fileSizeFormat(file.sizeBytes)}
          </Typography>
        </div>
        <div className={styles.fileDataActions}>
          {!file.deleted && (
            <>
              {showDownloadButton && (
                <IconButton
                  aria-label="download"
                  title="Скачать"
                  disabled={isLoading}
                  onClick={handleDownload}>
                  {isDownloading ? <Loader /> : <DownloadArrow />}
                </IconButton>
              )}
              {(canRemove || isSoftRemove) && !disabledFileItem && (
                <IconButton
                  aria-label="delete"
                  title="Удалить"
                  color="secondary"
                  disabled={isLoading || disabledDeleteButton}
                  onClick={() => setShowRemoveConfirm(true)}>
                  {isRemoving ? (
                    <Loader />
                  ) : (
                    <TrashIcon
                      sx={{
                        ...(disabledDeleteButton && { color: '#9FA8B3' }),
                      }}
                    />
                  )}
                </IconButton>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.fileUploadData}>
        {file.error ? (
          <Typography color="error" variant="body2">
            {file.error}
          </Typography>
        ) : (
          withSignature && (
            <>
              <Typography color="text.secondary" variant="body2">
                {formatServerDate(file.createdAt, 'dd.MM.yyyy в HH:mm')}
                <span className={styles.fileUploadDataAuthor}>
                  {joinFullName(
                    file?.createdByUser?.lastName,
                    file?.createdByUser?.firstName,
                    file?.createdByUser?.middleName
                  )}
                </span>
              </Typography>
              <WatchedStatus isWatched={file.isWatched} />
            </>
          )
        )}
      </div>

      {showRemoveConfirm && (
        <ConfirmDialog
          open
          close={() => setShowRemoveConfirm(false)}
          title="Удалить файл?"
          confirmText="Да, удалить"
          onConfirm={() => {
            setShowRemoveConfirm(false);
            handleRemove();
          }}>
          {removeConfirmText}
        </ConfirmDialog>
      )}
    </div>
  );
};
