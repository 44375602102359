import { FC, ReactNode, useCallback, useEffect, useState, Ref } from 'react';
import classNames from 'classnames';
import {
  Button,
  Typography,
  FileList,
  useFormControl,
  Box,
  ConfirmDialog,
} from 'src/components/ui';
import { useAppSelector } from 'src/store';
import { selectUser } from 'src/store/user';
import { plural } from 'src/utils';
import {
  getDataByExtension,
  getHelp,
  validateSizeByType,
} from 'src/components/ui/file-upload/utils';
import { useGetConfigQuery } from 'src/api/document';
import { AuthService } from 'src/services';
import { ContractDocumentTypes, DocumentTypes } from 'src/models';
import { TTypeConfig } from 'src/models/document';
import styles from './file-upload.module.scss';
import { FileDropZone } from '../file-drop-zone';

export type TFile = {
  name: string;
  sizeBytes: number;
  createdAt: string;
  createdByUser: {
    lastName: string;
    firstName: string;
    middleName?: string;
  };
  id: number;
  error?: string;
  deleted?: boolean;
  isWatched?: boolean | null;
  primary?: boolean;
} & ({ isNew: true; file: File } | { isNew?: never; file?: never });

export type TFileUpload = {
  value?: TFile[];
  fullScreen?: boolean;
  onChange?: (files: TFile[]) => void;
  onDownload?: (fileId: number) => void;
  onRemove?: (fileId: number) => void;
  removeMode?: 'default' | 'soft';
  documentType: DocumentTypes | ContractDocumentTypes;
  helpText?: string;
  showAvailableExtList?: boolean;
  showDownloadButton?: boolean;
  title?: ReactNode;
  actions?: ReactNode;
  subtitle?: ReactNode;
  className?: string;
  inputRef?: Ref<any>;
  imagesPreview?: boolean;
  variant?: 'default' | 'compact';
};

export type TLimit = {
  countOtherFiles: number | null;
  countArchive: number | null;
};
// TODO Sasha
// https://github.com/react-dropzone/react-dropzone/blob/v14.2.3/src/utils/index.js#L127
const isEventWithFiles = (event: any) => {
  if (!event.dataTransfer) {
    return !!event.target?.files;
  }

  return Array.prototype.some.call(
    event.dataTransfer.types,
    (type) => type === 'Files' || type === 'application/x-moz-file'
  );
};

const variantConfig = {
  default: {
    variant: 'h3',
    lineHeight: undefined,
    marginBottom: '19px',
  },
  compact: {
    variant: 'body2',
    lineHeight: '20px',
    marginBottom: undefined,
  },
} as const;

export const FileUpload: FC<TFileUpload> = ({
  value,
  fullScreen = true,
  onChange,
  onDownload,
  onRemove,
  removeMode = 'default',
  documentType,
  helpText,
  showAvailableExtList = false,
  showDownloadButton = true,
  title,
  actions,
  subtitle,
  className,
  inputRef,
  imagesPreview,
  variant = 'default',
}) => {
  const [limit, setLimit] = useState<TLimit>({
    countOtherFiles: null,
    countArchive: null,
  });
  const [errorValidate, setErrorValidate] = useState<string>();
  const [isDisable, setIsDisable] = useState(false);
  const user = useAppSelector(selectUser);
  const { error } = useFormControl() || {};
  const [showRemoveConfirm, setShowRemoveConfirm] = useState(false);

  const role = AuthService.currentRole;

  const { data: dataConfig } = useGetConfigQuery();

  const valueCounter = value?.filter((element) => !element.deleted).length;

  useEffect(() => {
    if (dataConfig && role && dataConfig[role]?.[documentType]) {
      const countOtherFiles = Number(
        dataConfig[role][documentType][TTypeConfig.OTHER_FILE_TYPES].count
      );
      const countArchive = Number(
        dataConfig[role][documentType][TTypeConfig.ARCHIVE].count
      );

      setLimit({
        countOtherFiles,
        countArchive,
      });

      if (
        value &&
        value.filter((elem) => !elem.deleted).length >=
          countOtherFiles + countArchive
      ) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    }
  }, [dataConfig, role, value, documentType]);

  const validateFiles = useCallback(
    (
      acceptedFiles: File[]
    ): {
      isValidate: boolean;
      messageError?: string;
    } => {
      const { countOtherFiles, countArchive } = limit;

      if (dataConfig && role && dataConfig[role]?.[documentType]) {
        const configByDocumentType = dataConfig[role][documentType];

        const validateExtensions = getDataByExtension(
          configByDocumentType,
          acceptedFiles
        );

        if (validateExtensions.error) {
          return {
            isValidate: false,
            messageError: 'Недопустимый формат файла',
          };
        }

        const prevFiles =
          value &&
          dataConfig &&
          role &&
          getDataByExtension(configByDocumentType, value);
        const acceptFiles =
          validateExtensions.dropFiles[TTypeConfig.OTHER_FILE_TYPES];
        const acceptArchive = validateExtensions.dropFiles[TTypeConfig.ARCHIVE];

        const fullFilesArray = prevFiles
          ? [
              ...acceptFiles,
              ...prevFiles.dropFiles[TTypeConfig.OTHER_FILE_TYPES].filter(
                (elem) => !elem.deleted
              ),
            ]
          : acceptFiles;

        const fullArchiveArray = prevFiles
          ? [
              ...acceptArchive,
              ...prevFiles.dropFiles[TTypeConfig.ARCHIVE].filter(
                (elem) => !elem.deleted
              ),
            ]
          : acceptArchive;

        if (fullFilesArray.length > Number(countOtherFiles)) {
          return {
            isValidate: false,
            messageError: `Можно загрузить до ${plural(
              Number(countOtherFiles),
              '$d файла',
              '$d файлов',
              '$d файлов'
            )}`,
          };
        }

        if (fullArchiveArray.length > Number(countArchive)) {
          return {
            isValidate: false,
            messageError: `Можно загрузить до ${plural(
              Number(countArchive),
              '$d архива',
              '$d архивов',
              '$d архивов'
            )}`,
          };
        }

        const validatedSizeFiles = validateSizeByType(
          acceptFiles,
          configByDocumentType,
          TTypeConfig.OTHER_FILE_TYPES
        );
        if (validatedSizeFiles) {
          return {
            isValidate: validatedSizeFiles.isValidate,
            messageError: validatedSizeFiles.messageError,
          };
        }

        const validatedSizeArchive = validateSizeByType(
          acceptArchive,
          configByDocumentType,
          TTypeConfig.ARCHIVE
        );
        if (validatedSizeArchive) {
          return {
            isValidate: validatedSizeArchive.isValidate,
            messageError: validatedSizeArchive.messageError,
          };
        }
      }

      return {
        isValidate: true,
      };
    },
    [limit, value, dataConfig, role, documentType]
  );

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // TODO useDropzone поддерживает только один параметр проверки на превышение максимального кол-ва файлов
      // TODO функция validator не имеет доступа ко всем файлам
      const { isValidate, messageError } = validateFiles(acceptedFiles);
      if (!isValidate) {
        setErrorValidate(messageError);
      } else if (onChange) {
        setErrorValidate(undefined);
        const resultFiles: TFile[] = acceptedFiles.map((file) => ({
          name: file.name,
          sizeBytes: file.size,
          createdAt: new Date().toISOString(),
          createdByUser: {
            lastName: user.lastName,
            firstName: user.firstName,
            middleName: user.middleName,
          },
          id: Math.random(),
          isNew: true,
          file,
        }));
        onChange(value ? resultFiles.concat(...value) : resultFiles);
      }
    },
    [onChange, user, value, validateFiles]
  );

  const handleClearAllDocument = () => {
    if (!onChange || !value) return;

    onChange(
      value
        .filter((element) => !element.isNew)
        .map((element) => ({
          ...element,
          deleted: true,
        }))
    );
  };

  const help =
    helpText ||
    (dataConfig && role && dataConfig[role]?.[documentType]
      ? getHelp(dataConfig[role][documentType], showAvailableExtList)
      : '');

  const getBottomOffset = () => {
    if (variant === 'default' && !title && !actions) {
      return undefined;
    }

    return variantConfig[variant].marginBottom;
  };

  return (
    <div
      className={classNames(
        styles.wrapper,
        (error || errorValidate) && styles.error,
        className
      )}>
      <Box display="flex" justifyContent="space-between" mb={getBottomOffset()}>
        <Typography
          variant={variantConfig[variant].variant}
          lineHeight={variantConfig[variant].lineHeight}>
          {title}
        </Typography>

        <Box display="flex" gap="24px">
          {actions}
        </Box>
      </Box>
      {subtitle}
      <FileDropZone
        onDrop={onDrop}
        isDisable={isDisable}
        inputRef={inputRef}
        fullScreen={fullScreen}
      />
      <Typography className={styles.help} color="text.secondary">
        {errorValidate || help}
      </Typography>
      {!valueCounter ? null : (
        <Box mt="24px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between">
            <Typography variant="h4">
              Загружено:&nbsp;
              {valueCounter}
              {`/${Number(limit.countOtherFiles) + Number(limit.countArchive)}`}
            </Typography>

            {value && value.length > 1 && (
              <Button
                onClick={() => setShowRemoveConfirm(true)}
                aria-label="clear all"
                title="Очистить все"
                variant="text">
                Очистить все
              </Button>
            )}
          </Box>

          <FileList
            value={value}
            onChange={onChange}
            onDownload={onDownload}
            onRemove={onRemove}
            removeMode={removeMode}
            showDownloadButton={showDownloadButton}
            imagesPreview={imagesPreview}
          />
        </Box>
      )}

      {showRemoveConfirm && (
        <ConfirmDialog
          open
          close={() => setShowRemoveConfirm(false)}
          title="Удалить все файлы?"
          confirmText="Да, удалить"
          onConfirm={() => {
            setShowRemoveConfirm(false);
            handleClearAllDocument();
          }}
        />
      )}
    </div>
  );
};
