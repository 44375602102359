import { IndexRouteObject, NonIndexRouteObject } from 'react-router-dom';
import { AuthRoute } from 'src/components/auth-route';
import { RouterErrorBoundary } from 'src/components/error-boundary';
import {
  LazyAdminCompanyLayout,
  LazyCompanyLayout,
  LazyMainBidsLayout,
  LazyPageLayout,
  LazySystemLayout,
  LazySystemSettingsLayout,
} from 'src/components/layout';
import {
  LazyChat,
  LazyCommunications,
  LazyContractorAllRequests,
  LazyContractorBusinessRequests,
  LazyContractors,
  LazyCustomerBusinessRequests,
  LazyCustomers,
  LazyForgotPassword,
  LazyLogin,
  LazyManagerBusinessRequests,
  LazyNewsDetail,
  LazyNotifications,
  LazyRegistration,
  LazyRestorePassword,
  LazySectionList,
  LazySectionNewsList,
  LazySpecificRoleBusinessRequests,
  PageNotFound,
} from 'src/components/pages';
import {
  LazyCompanies as LazyAdminCompanies,
  LazyContractTemplates as LazyAdminContractTemplates,
  LazyDigest as LazyAdminDigest,
  LazyEmailAccounts as LazyAdminEmailAccounts,
  LazyEmailFilters as LazyAdminEmailFilters,
  LazyEvents as LazyAdminEvents,
  LazyExtra as LazyAdminExtra,
  LazyImports as LazyAdminImports,
  LazyNewsDetail as LazyAdminNewsDetail,
  LazyNotifications as LazyAdminNotifications,
  LazyRefusalReason as LazyAdminRefusalReason,
  LazySectionList as LazyAdminSectionList,
  LazySectionNewsList as LazyAdminSectionNewsList,
  LazyShipping as LazyAdminShipments,
  LazyTechnicalOpportunities as LazyAdminTechnicalOpportunities,
  LazyUsers as LazyAdminUsers,
  LazyGroups as LazyAdminGroups,
  LazyGroupDetails as LazyAdminGroupDetails,
} from 'src/components/pages/admin';
import { LazyNewsForm as LazyAdminNewsForm } from 'src/components/pages/admin/news';
import { LazyPayments } from 'src/components/pages/admin/payments';
import { LazyCreateBid } from 'src/components/pages/create-bid';
import { LazyMain } from 'src/components/pages/main';
import { ProtectedRoute } from 'src/components/protected-route';
import { ADMIN_BASENAME } from 'src/configs/admin-basename';
import {
  contractorRouteItems as adminContractorRouteItems,
  customerRouteItems as adminCustomerRouteItems,
} from 'src/configs/admin/company';
import {
  contractorBusinessRequestRouteItems,
  contractorContractorRequestRouteItems,
  customerBusinessRequestRouteItems,
  managerBusinessRequestRouteItems,
  specificRoleBusinessRequestRouteItems,
} from 'src/configs/business-request';
import {
  companyRouteItems,
  contractorRouteItems,
  customerRouteItems,
} from 'src/configs/company';
import { PERMISSIONS } from 'src/constants';
import { BusinessRequestMode } from 'src/models';

type TRoute =
  | (IndexRouteObject & { permissions?: PERMISSIONS[] })
  | (Omit<NonIndexRouteObject, 'children'> & {
      permissions?: PERMISSIONS[];
      children?: TRoute[];
    });

export type TRouteConfig = Array<TRoute>;

export const ROUTES: TRouteConfig = [
  {
    errorElement: <RouterErrorBoundary />,
    children: [
      {
        element: <ProtectedRoute />,
        children: [
          {
            path: '/',
            element: <LazyPageLayout />,
            permissions: [PERMISSIONS.VIEW_CLIENT_PANEL],
            children: [
              {
                errorElement: <RouterErrorBoundary />,
                children: [
                  {
                    index: true,
                    element: <LazyMain />,
                  },
                  {
                    path: 'requests',
                    permissions: [
                      PERMISSIONS.VIEW_MANAGER_BUSINESS_REQUEST_LIST,
                    ],
                    children: [
                      {
                        index: true,
                        element: <LazyManagerBusinessRequests />,
                      },
                      {
                        path: 'new',
                        element: <LazyCreateBid />,
                        permissions: [PERMISSIONS.CREATE_REQUEST],
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.MANAGER}
                          />
                        ),
                        children: [...managerBusinessRequestRouteItems],
                      },
                    ],
                  },
                  {
                    path: 'requests',
                    permissions: [
                      PERMISSIONS.VIEW_CUSTOMER_BUSINESS_REQUEST_LIST,
                    ],
                    children: [
                      {
                        index: true,
                        element: <LazyCustomerBusinessRequests />,
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.CUSTOMER}
                          />
                        ),
                        children: [...customerBusinessRequestRouteItems],
                      },
                    ],
                  },
                  {
                    path: 'requests',
                    permissions: [
                      PERMISSIONS.VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST,
                    ],
                    children: [
                      {
                        index: true,
                        element: <LazyContractorBusinessRequests />,
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.CONTRACTOR}
                          />
                        ),
                        children: [...contractorContractorRequestRouteItems],
                      },
                    ],
                  },
                  {
                    path: 'all-requests',
                    permissions: [
                      PERMISSIONS.VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST,
                    ],
                    children: [
                      {
                        index: true,
                        element: <LazyContractorAllRequests />,
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.CONTRACTOR_ALL}
                          />
                        ),
                        children: [...contractorBusinessRequestRouteItems],
                      },
                    ],
                  },
                  // TODO: Проверить все!!!
                  {
                    path: 'requests',
                    permissions: [
                      PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
                    ],
                    children: [
                      {
                        index: true,
                        element: <LazySpecificRoleBusinessRequests />,
                      },
                      {
                        path: 'new',
                        element: <LazyCreateBid />,
                        permissions: [PERMISSIONS.CREATE_REQUEST],
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.SPECIFIC_ROLE}
                          />
                        ),
                        children: [...specificRoleBusinessRequestRouteItems],
                      },
                    ],
                  },
                  {
                    path: 'contract-templates',
                    element: <LazyAdminContractTemplates />,
                    permissions: [PERMISSIONS.VIEW_TEMPLATES],
                  },
                  {
                    path: 'shipments',
                    element: <LazyAdminShipments />,
                    permissions: [PERMISSIONS.VIEW_SHIPMENTS],
                  },
                  {
                    path: 'payments',
                    element: <LazyPayments />,
                    permissions: [PERMISSIONS.VIEW_PAYMENTS],
                  },
                  {
                    path: 'companies',
                    permissions: [PERMISSIONS.VIEW_ALL_COMPANIES],
                    children: [
                      {
                        index: true,
                        element: <LazyAdminCompanies />,
                      },
                      {
                        path: 'customers/:id',
                        element: <LazyAdminCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...adminCustomerRouteItems],
                          },
                        ],
                      },
                      {
                        path: 'contractors/:id',
                        element: <LazyAdminCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...adminContractorRouteItems],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'all-requests',
                    permissions: [
                      PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
                    ],
                    children: [
                      {
                        index: true,
                        element: <LazyManagerBusinessRequests />,
                      },
                      {
                        path: 'new',
                        element: <LazyCreateBid />,
                        permissions: [PERMISSIONS.CREATE_REQUEST],
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.MANAGER}
                          />
                        ),
                        children: [...managerBusinessRequestRouteItems],
                      },
                    ],
                  },
                  // TODO: Может ли техэксперт видеть эту страницу?
                  {
                    path: 'companies/contractors',
                    permissions: [PERMISSIONS.VIEW_CONTRACTOR_LIST],
                    children: [
                      {
                        index: true,
                        element: <LazyContractors />,
                      },
                      {
                        path: ':id',
                        element: <LazyCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...contractorRouteItems],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'companies/customers',
                    permissions: [PERMISSIONS.VIEW_CUSTOMER_LIST],
                    children: [
                      {
                        index: true,
                        element: <LazyCustomers />,
                      },
                      {
                        path: ':id',
                        element: <LazyCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...customerRouteItems],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'companies/:id',
                    element: <LazyCompanyLayout />,
                    permissions: [PERMISSIONS.VIEW_OWN_COMPANY],
                    children: [
                      {
                        errorElement: <RouterErrorBoundary />,
                        children: [...companyRouteItems],
                      },
                    ],
                  },
                  {
                    path: 'news',
                    children: [
                      {
                        index: true,
                        element: <LazySectionList />,
                      },
                      {
                        path: ':sectionName',
                        children: [
                          {
                            index: true,
                            element: <LazySectionNewsList />,
                          },
                          {
                            path: ':id',
                            element: <LazyNewsDetail />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'chat',
                    element: <LazyChat />,
                    children: [
                      {
                        path: ':id',
                        element: <LazyChat />,
                      },
                    ],
                  },
                  {
                    path: 'notifications',
                    element: <LazyNotifications />,
                  },
                  {
                    path: 'communications/:type',
                    element: <LazyCommunications />,
                    permissions: [PERMISSIONS.VIEW_COMMUNICATION_PAGE],
                  },
                ],
              },
            ],
          },
          {
            path: ADMIN_BASENAME,
            element: <LazyPageLayout />,
            permissions: [PERMISSIONS.VIEW_ADMIN_PANEL],
            children: [
              {
                errorElement: <RouterErrorBoundary />,
                children: [
                  {
                    index: true,
                    element: <LazyMain />,
                  },
                  {
                    path: 'users',
                    element: <LazyAdminUsers />,
                  },
                  {
                    path: 'requests',
                    children: [
                      {
                        index: true,
                        element: <LazyManagerBusinessRequests />,
                      },
                      {
                        path: 'new',
                        element: <LazyCreateBid />,
                      },
                      {
                        path: ':id',
                        element: (
                          <LazyMainBidsLayout
                            mode={BusinessRequestMode.MANAGER}
                          />
                        ),
                        children: [...managerBusinessRequestRouteItems],
                      },
                    ],
                  },
                  {
                    path: 'companies/contractors',
                    children: [
                      {
                        index: true,
                        element: <LazyContractors />,
                      },
                      {
                        path: ':id',
                        element: <LazyAdminCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...adminContractorRouteItems],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'companies/customers',
                    children: [
                      {
                        index: true,
                        element: <LazyCustomers />,
                      },
                      {
                        path: ':id',
                        element: <LazyAdminCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...adminCustomerRouteItems],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'shipments',
                    element: <LazyAdminShipments />,
                  },
                  {
                    path: 'payments',
                    element: <LazyPayments />,
                  },
                  {
                    path: 'companies',
                    children: [
                      {
                        index: true,
                        element: <LazyAdminCompanies />,
                      },
                      {
                        path: 'customers/:id',
                        element: <LazyAdminCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...adminCustomerRouteItems],
                          },
                        ],
                      },
                      {
                        path: 'contractors/:id',
                        element: <LazyAdminCompanyLayout />,
                        children: [
                          {
                            errorElement: <RouterErrorBoundary />,
                            children: [...adminContractorRouteItems],
                          },
                        ],
                      },
                    ],
                  },
                  // TODO: Почему index не срабатывает?
                  {
                    path: 'system-settings',
                    element: <LazySystemSettingsLayout />,
                    children: [
                      {
                        index: true,
                        path: 'queues',
                        element: <LazyAdminExtra />,
                      },
                      {
                        path: 'email-filters',
                        element: <LazyAdminEmailFilters />,
                      },
                      {
                        path: 'email-accounts',
                        element: <LazyAdminEmailAccounts />,
                      },
                      {
                        path: 'digest',
                        element: <LazyAdminDigest />,
                      },
                      {
                        path: 'user-groups',
                        element: <LazyAdminGroups />,
                      },
                      {
                        path: 'user-group-details/:id',
                        element: <LazyAdminGroupDetails />,
                      },
                      {
                        path: 'documents',
                        element: <LazyAdminContractTemplates />,
                      },
                      {
                        path: 'events',
                        element: <LazyAdminEvents />,
                      },
                      {
                        path: 'imports',
                        element: <LazyAdminImports />,
                      },
                      {
                        path: 'reasons/:type',
                        element: <LazyAdminRefusalReason />,
                      },
                      {
                        path: 'notification-templates',
                        element: <LazyAdminNotifications />,
                      },
                      {
                        index: true,
                        path: 'tags',
                        element: <LazyAdminTechnicalOpportunities />,
                      },
                    ],
                  },
                  {
                    path: 'news',
                    children: [
                      {
                        index: true,
                        element: <LazyAdminSectionList />,
                      },
                      {
                        path: 'create',
                        children: [
                          {
                            index: true,
                            element: <LazyAdminNewsForm />,
                          },
                          {
                            path: ':sectionName',
                            element: <LazyAdminNewsForm />,
                          },
                        ],
                      },
                      {
                        path: ':sectionName',
                        children: [
                          {
                            index: true,
                            element: <LazyAdminSectionNewsList />,
                          },
                          {
                            path: ':id',
                            element: <LazyAdminNewsDetail />,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: 'communications/:type',
                    element: <LazyCommunications />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        element: <AuthRoute />,
        children: [
          {
            element: <LazySystemLayout />,
            children: [
              {
                errorElement: <RouterErrorBoundary />,
                children: [
                  {
                    path: '/login',
                    element: <LazyLogin />,
                  },
                  {
                    path: '/forgot-password',
                    element: <LazyForgotPassword />,
                  },
                  {
                    path: '/registration',
                    element: <LazyRegistration />,
                  },
                  {
                    path: '/restore-password/:confirmToken',
                    element: <LazyRestorePassword />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: '*',
        element: <PageNotFound />,
      },
    ],
  },
];
