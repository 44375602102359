import classNames from 'classnames';
import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Box, BoxProps, IconButton, SvgIcon } from 'src/components/ui';
import vars from 'src/theme/scss/_vars.scss';
import { ArrowBack, BurgerMenuNew } from 'src/assets/icons';
import { useScrollTrigger } from 'src/hooks';
import { Theme, useMediaQuery } from '@mui/material';
import styles from './fixed-header.module.scss';

type TFixedHeaderProps = {
  onClick?: () => void;
  hideBackButton?: boolean;
  onMenuClick?: () => void;
} & BoxProps;

export const FixedHeader: FC<PropsWithChildren<TFixedHeaderProps>> = ({
  children,
  onClick,
  onMenuClick,
  hideBackButton = false,
  className,
  ...props
}) => {
  const { isActive } = useScrollTrigger({ triggerValue: 5 });

  const containerRef = useRef<HTMLDivElement>(null);

  const [boxPb, setBoxPb] = useState('');

  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  useEffect(() => {
    const pb = containerRef?.current?.offsetHeight || 60;

    setBoxPb(`${Math.max(pb, 60)}px`);
  });

  return (
    <Box
      sx={{
        pb: boxPb,
      }}>
      <div
        style={{ top: vars.headerHeight }}
        className={classNames(
          styles.container,
          isActive && styles.isShowBackground
        )}
        ref={containerRef}>
        <Box
          width="100%"
          height="100%"
          position="relative"
          display="flex"
          alignItems="center"
          gap={2.25}
          sx={{
            overflow: 'hidden',
          }}>
          {!hideBackButton && (
            <IconButton onClick={onClick}>
              <ArrowBack
                color="primary"
                sx={() => ({
                  width: '24px',
                  height: '24px',
                  color: 'theme.palette.primary.main',
                })}
              />
            </IconButton>
          )}
          <Box className={classNames(styles.content, className)} {...props}>
            {children}
          </Box>
        </Box>
        {onMenuClick && isMobile && (
          <SvgIcon icon={BurgerMenuNew} onClick={onMenuClick} />
        )}
      </div>
    </Box>
  );
};
