import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Form,
  Autocomplete,
} from 'src/components/ui';
import {
  TAssignedManager,
  useGetAssignedManagersListMutation,
} from 'src/api/admin/company';
import { DialogSkeleton } from 'src/components/ui/skeleton';
import { getErrorMessage } from 'src/store/utils';
import { useForm } from 'react-hook-form';
import { useSetAssignmentManagerMutation } from 'src/api/business-request/business-request';
import { IManagerBusinessRequest } from 'src/models';
import { ROLES } from 'src/constants';
import { joinFullName, getSortedUsers } from 'src/utils';
import { TModeChangeManager } from 'src/components/layout/bids-layout/main-bids-layout/components/header/components/assigned-managers/assigned-managers';

interface IChangeManagerProps {
  open: boolean;
  businessRequest: IManagerBusinessRequest;
  close: () => void;
  mode: TModeChangeManager;
}

interface IInputForm {
  manager: TAssignedManager;
}

export const ChangeManager: FC<IChangeManagerProps> = ({
  open,
  businessRequest,
  close,
  mode,
}) => {
  const [getAssignedManagers, { data: listManagers, isLoading }] =
    useGetAssignedManagersListMutation();
  const [setAssignmentManager, { isLoading: isLoadingSetManager }] =
    useSetAssignmentManagerMutation();

  const form = useForm<IInputForm>({
    defaultValues: {
      manager: {
        userId: 0,
        firstName: '',
        lastName: '',
        middleName: '',
        role: [ROLES.KAM],
      },
    },
  });

  const handleSubmitForm = async (data: IInputForm) => {
    try {
      await setAssignmentManager({
        businessRequestId: businessRequest.businessRequestId,
        userId: data.manager.userId,
        type: mode,
      })
        .unwrap()
        .then(() => {
          close();
        })
        .catch((e) => {
          form.setError('root.serverError', {
            type: 'custom',
            message: getErrorMessage(
              e,
              'Произошла ошибка при сохранении данных'
            ),
          });
        });
    } catch {
      // ignore
    }
  };

  const fetchApiFilter = useCallback(
    (value: string) => {
      const filteredItems =
        (value
          ? listManagers?.filter(
              (element) =>
                element.lastName?.toLowerCase().includes(value.toLowerCase()) ||
                element.firstName
                  ?.toLowerCase()
                  .includes(value.toLowerCase()) ||
                element.middleName?.toLowerCase().includes(value.toLowerCase())
            )
          : listManagers) || [];

      return Promise.resolve(getSortedUsers(filteredItems));
    },
    [listManagers]
  );

  const options = useMemo(() => {
    const filteredItems = listManagers?.filter((element) =>
      element.role.includes(mode === 'kam' ? ROLES.KAM : ROLES.KAM_PLUS)
    );

    return getSortedUsers(filteredItems);
  }, [listManagers, mode]);

  useEffect(() => {
    getAssignedManagers();
  }, [getAssignedManagers]);

  return (
    <Dialog
      open={open}
      close={close}
      PaperProps={{
        sx: {
          width: '500px',
        },
      }}>
      {isLoading ? (
        <DialogSkeleton width="500px" height="318px" />
      ) : (
        <>
          <DialogTitle>
            Смена менеджера по {mode === 'kam' ? 'заявке' : 'заказу'}
          </DialogTitle>

          <DialogContent>
            <Form
              form={form}
              onSubmit={handleSubmitForm}
              loading={isLoadingSetManager}>
              <Form.Item
                viewMode={false}
                required
                label="Выберите менеджера"
                name="manager">
                <Autocomplete<TAssignedManager>
                  popupIcon
                  getOptionLabel={(option) =>
                    joinFullName(
                      option.lastName,
                      option.firstName,
                      option.middleName
                    ) || ''
                  }
                  isOptionEqualToValue={(option, value) =>
                    option.userId === value.userId
                  }
                  options={options}
                  fetchApi={fetchApiFilter}
                  fetchOnEmptyInput
                  placeholder="Выбрать"
                />
              </Form.Item>
            </Form>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
};
