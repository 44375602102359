import { forwardRef } from 'react';
import { Button as MuiButton } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import { Loader } from 'src/components/ui/loader';

export type TButton = ButtonProps & { loading?: boolean };

export const Button = forwardRef<any, TButton>(
  ({ loading, startIcon, variant, ...props }, ref) => {
    const loadingByVariant = variant === 'text' ? 'loadingText' : 'loading';

    return (
      <MuiButton
        ref={ref}
        {...props}
        variant={loading ? loadingByVariant : variant}
        startIcon={loading ? <Loader /> : startIcon}
      />
    );
  }
);
