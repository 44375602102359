import { Components } from '@mui/material/styles/components';
import {
  CheckboxChecked,
  CheckboxUnchecked,
  CheckboxSelected,
} from 'src/assets/icons';

export const MuiCheckbox: Components['MuiCheckbox'] = {
  defaultProps: {
    disableRipple: true,
    icon: <CheckboxUnchecked />,
    checkedIcon: <CheckboxChecked />,
    indeterminateIcon: <CheckboxSelected />,
  },
  styleOverrides: {
    root: {
      padding: '0 7px 0 0',
    },
  },
};
