import { ComponentProps, FC } from 'react';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers';
import { OutlinedInput } from 'src/components/ui';
import { DateIcon } from 'src/assets/icons';

type TMuiDateTimePicker = ComponentProps<typeof MuiDateTimePicker>;

type TDateTimePicker = Omit<
  TMuiDateTimePicker,
  'renderInput' | 'value' | 'onChange'
> & {
  value?: string;
  onChange?: TMuiDateTimePicker['onChange'];
  placeholder?: string;
  isMinDate?: boolean;
};

const onBlur = (
  value: string | undefined,
  onChange: (value: unknown) => void
) => {
  if (!value) {
    return;
  }
  const inputDate = new Date(value);
  if (inputDate.getFullYear() < 100) {
    inputDate.setFullYear(2000 + inputDate.getFullYear());
    onChange(inputDate);
  }
};

export const DateTimePicker: FC<TDateTimePicker> = ({
  onChange = () => {},
  value,
  placeholder = 'Введите дату',
  isMinDate = true,
  ...props
}) => (
  <MuiDateTimePicker
    {...props}
    onChange={onChange}
    value={value}
    components={{
      OpenPickerIcon: DateIcon,
    }}
    renderInput={({ inputRef, inputProps, InputProps }) => (
      <OutlinedInput
        trimValue={false}
        inputRef={inputRef}
        {...InputProps}
        inputProps={{ ...inputProps, placeholder }}
        onBlur={() => isMinDate && onBlur(value, onChange)}
      />
    )}
  />
);
