import { InfoHelpIcon } from 'src/assets/icons';
import { TableCell } from '@mui/material';
import { Box, Tooltip } from 'src/components/ui';
import { ColumnType } from 'src/components/ui/new-table/types';

import styles from './th-cell.module.scss';

interface ThCellProps<RecordType> {
  column: ColumnType<RecordType>;
}

export const ThCell = <RecordType,>({ column }: ThCellProps<RecordType>) => {
  let justifyCellContent;
  switch (column.setting?.align) {
    case 'center':
      justifyCellContent = 'center';
      break;
    case 'right':
      justifyCellContent = 'end';
      break;
    case 'left':
      justifyCellContent = 'start';
      break;
    default:
      justifyCellContent = 'start';
  }
  return (
    <TableCell {...column.setting} className={styles.thCell}>
      <Box
        display="flex"
        justifyContent={justifyCellContent}
        alignItems="center"
        gap="8px">
        <span className={styles.thCellContent}>{column.title}</span>

        {column.tooltip && (
          <Tooltip title={column.tooltip}>
            <InfoHelpIcon />
          </Tooltip>
        )}
      </Box>
    </TableCell>
  );
};
