import { FC } from 'react';
import { Box } from 'src/components/ui';
import {
  AttachFiles,
  CompanyContractStatus,
  OpenChat,
} from 'src/components/widgets';
import {
  ContractStatuses,
  ConversationEntityType,
  DocumentEntityTypes,
  DocumentTypes,
  ICargoDescriptions,
} from 'src/models';
import { arrayRequired } from 'src/utils/form-rules';
import { Finance, Comments, Deadlines, Logistics } from '../components';

interface IContractorCommercialOfferProps {
  isEditing: boolean;
  shippingIncluded: boolean;

  logistics: {
    deliveryAddress: string;
    shippingAddress: string;

    cargoDescription: ICargoDescriptions | null;

    costsLogistics: number;
    calculatedShippingCost: number;
  };
  isAuction: boolean;

  contractorDetails: {
    contractStatusCode: ContractStatuses;
    organizationId: number;
    contractorRequestId: number;
    commercialOfferId: number;
  };

  handleSubmitTransportCalculation: (value: ICargoDescriptions) => void;

  orderProcessingStarted: boolean;
}

const mode = 'contractor';

export const ContractorCommercialOffer: FC<IContractorCommercialOfferProps> = ({
  isEditing,
  shippingIncluded,
  logistics,
  isAuction,
  contractorDetails,
  handleSubmitTransportCalculation,
  orderProcessingStarted,
}) => (
  <>
    <Finance
      isEditing={isEditing}
      shippingIncluded={shippingIncluded}
      isAuction={isAuction}
      mode={mode}
      costsLogisticsIsManually={
        Number(logistics?.costsLogistics) !==
        Number(logistics?.calculatedShippingCost)
      }
    />

    {shippingIncluded && (
      <Logistics
        isEditing={isEditing}
        mode={mode}
        handleSubmitTransportCalculation={handleSubmitTransportCalculation}
      />
    )}

    <Deadlines isEditing={isEditing} mode={mode} />

    <Box mb="24px">
      <CompanyContractStatus
        contractStatus={contractorDetails.contractStatusCode}
        organizationId={contractorDetails.organizationId}
      />
    </Box>

    {!orderProcessingStarted && (
      <OpenChat
        entityType={ConversationEntityType.CONTRACTOR_REQUEST}
        entityId={contractorDetails.contractorRequestId}
        mode="manager"
        sx={{ marginBottom: '24px' }}
      />
    )}

    <Comments isEditing={isEditing} />

    <Box mb="24px">
      <AttachFiles
        noMargin
        formItemName="documents"
        isEditMode={isEditing}
        rules={{ validate: arrayRequired }}
        documentOptions={{
          entityType: DocumentEntityTypes.CONTRACTOR_COMMERCIAL_OFFER,
          entityId: contractorDetails.commercialOfferId,
          documentType: DocumentTypes.COMMERCIAL_OFFER,
        }}
        fullScreen={false}
      />
    </Box>
  </>
);
