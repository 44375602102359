import { PERMISSIONS, ROLES } from 'src/constants';
import { ROLE_PERMISSIONS } from 'src/configs/role-permissions';

export class RolePermissions implements IRolePermissions {
  private _accessPermissions: { [k in PERMISSIONS]?: true } = {};

  private _hasAccess: boolean = false;

  get hasAccess() {
    return this._hasAccess;
  }

  init(role: ROLES) {
    const rolePermissions = ROLE_PERMISSIONS[role];

    if (rolePermissions) {
      this._accessPermissions = rolePermissions.reduce(
        (acc: { [k in PERMISSIONS]?: true }, permission) => {
          acc[permission] = true;

          return acc;
        },
        {}
      );

      this._hasAccess = true;
    } else {
      this.reset();
    }
  }

  reset() {
    this._accessPermissions = {};
    this._hasAccess = false;
  }

  can(permissions?: PERMISSIONS | PERMISSIONS[]) {
    if (!permissions) {
      return this.hasAccess;
    }

    return Array.isArray(permissions)
      ? permissions.some((permission) => !!this._accessPermissions[permission])
      : !!this._accessPermissions[permissions];
  }
}

export const RolePermissionService = new RolePermissions();

export interface IRolePermissions {
  readonly hasAccess: boolean;
  init(role: ROLES): void;
  reset(): void;
  can(permission?: PERMISSIONS): boolean;
}
