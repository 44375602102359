import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { DIRECTION, TConfigFilter } from 'src/components/ui/filters/types';
import { TUserList } from 'src/models/admin';
import { TUser } from 'src/models/admin/user';
import { TPhoneInputValue } from 'src/components/ui/phone-input';
import { ROLES } from 'src/constants';
import { TFormData } from 'src/components/pages/admin/users/components/user-modal/types';

export type TSortKey =
  | 'REGISTRATION_TIMESTAMP'
  | 'LAST_ACTIVITY_TIMESTAMP'
  | 'ORGANIZATION_NAME'
  | 'FIO';
export type TUserListParams = {
  sort: {
    field: TSortKey;
    order: DIRECTION;
  };
  pagingDto: {
    page: number;
    limit: number;
  };
  searchQueries: Array<string>;
  filter: TUserFilterParams;
};

export type TUpdateUser = { lastName: string } & Pick<
  TUser,
  | 'id'
  | 'login'
  | 'firstName'
  | 'middleName'
  | 'position'
  | 'contactInfoDto'
  | 'organizationName'
>;

export type TCreateUser = {
  amoCrmId: number | null;
  login: string;
  lastName: string;
  firstName: string;
  middleName: string | null;
  position: string | null;
  contactInfo: {
    phones: TPhoneInputValue[];
  };
  sipuniKamNumber: number | string;
};

export type TUserFilters = {
  [key in TConfigFilter['name']]:
    | Array<string>
    | Array<number>
    | TPhoneInputValue[];
};

export type TUserFilterParams = {
  userIds: Array<string>;
  fio: Array<string>;
  organizationNames: Array<string>;
  phones: Array<string>;
  lastActivityDate: {
    from: string;
    to: string;
  };
  registrationDate: {
    from: string;
    to: string;
  };
  roles: Array<ROLES>;
  statuses: Array<string>;
};

export enum ActionsChangeStatus {
  'lock' = 'lock',
  'unlock' = 'unlock',
  'delete' = 'delete',
}

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getList: builder.query<TUserList, TUserListParams>({
      query: (body) => ({
        url: 'users-service/api/admin/v1/user',
        method: 'POST',
        body,
      }),
    }),
    getUserFilters: builder.query<TUserFilters, void>({
      query: () => ({
        url: 'users-service/api/v1/user/filters',
        method: 'GET',
      }),
    }),
    updateUserById: builder.mutation<
      TUpdateUser,
      Partial<TFormData> & { roleType?: ROLES }
    >({
      query: ({ id, roleType, ...body }) => ({
        url: roleType
          ? `mph-security-provider/api/v1/user/${id}?roleType=${roleType}`
          : `mph-security-provider/api/v1/user/${id}`,
        method: 'PUT',
        body,
      }),
    }),

    createUser: builder.mutation<
      { kamId: number },
      { data: TCreateUser; isKamPlus: boolean }
    >({
      query: ({ data, isKamPlus }) => ({
        url: `mph-security-provider/api/v1/registration/${
          isKamPlus ? 'kam-expert' : 'kam'
        }`,
        method: 'POST',
        body: data,
      }),
    }),

    createSpecificUser: builder.mutation<
      { roleSpecificId: number },
      { data: TCreateUser; currentRole: ROLES; roleType: ROLES }
    >({
      query: ({ data, currentRole, roleType }) => ({
        url: `mph-security-provider/api/v1/registration/specific-role?roleType=${roleType}`,
        method: 'POST',
        body: data,
        headers: {
          // authorization: `Bearer ${AuthService.parentAccessToken}`,
          'Current-Role': currentRole,
        },
      }),
    }),

    changeStatusUsers: builder.mutation<
      void,
      { userIds: Array<number>; typeAction: ActionsChangeStatus }
    >({
      query: ({ userIds, typeAction }) => ({
        url: `mph-security-provider/api/admin/v1/mass/user/${typeAction}`,
        method: 'PATCH',
        body: { userIds },
      }),
    }),
  }),
});

export const {
  useLazyGetListQuery,
  useCreateUserMutation,
  useCreateSpecificUserMutation,
  useUpdateUserByIdMutation,
  useLazyGetUserFiltersQuery,
  useChangeStatusUsersMutation,
} = adminUserApi;
