import { Tooltip, Box } from 'src/components/ui';
import { FC, PropsWithChildren } from 'react';
import {
  Approve,
  ExclamationIcon,
  ExpertBarWarningIcon,
  InfoHelpIcon,
  WarningSimple,
} from 'src/assets/icons';
import cn from 'classnames';
import { BoxProps } from '@mui/material';
import styles from './hint-bar.module.scss';

export type HelpExpertStatuses = 'REJECTED' | 'APPROVED' | 'PENDING';

export interface IHintBarProps extends BoxProps {
  status?: null | HelpExpertStatuses;
  tooltip?: React.ReactNode;
  after?: React.ReactNode;
}

const renderIcon = (status: HelpExpertStatuses | null) => {
  if (status === 'APPROVED') {
    return <Approve width={24} height={24} />;
  }
  if (status === 'REJECTED') {
    return <ExclamationIcon width={24} height={24} />;
  }
  if (status === 'PENDING') {
    return <ExpertBarWarningIcon width={24} height={24} />;
  }

  return <InfoHelpIcon />;
};

export const HintBar: FC<PropsWithChildren<IHintBarProps>> = ({
  children,
  tooltip,
  status = null,
  after,
  ...props
}) => (
  <Box
    display="flex"
    alignItems="center"
    justifyContent="space-between"
    className={cn(
      styles.wrapper,
      status === 'PENDING' && styles.pending,
      status === 'REJECTED' && styles.rejected,
      status === 'APPROVED' && styles.approved
    )}
    {...props}>
    <Box
      display="flex"
      alignItems="center"
      gap={1}
      className={styles.barContent}>
      <Box className={styles.icon}>
        {tooltip ? (
          <Tooltip title={tooltip}>{renderIcon(status)}</Tooltip>
        ) : (
          renderIcon(status)
        )}
      </Box>
      {children}
      <Box flex={1} display="flex" justifyContent="end">
        {after}
      </Box>
    </Box>
  </Box>
);
