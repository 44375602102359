import { FC } from 'react';
import {
  ConversationEntityType,
  IManagerCustomerSpecification,
} from 'src/models';
import { InfoRow, Link } from 'src/components/ui';
import { OpenChat } from 'src/components/widgets';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Deadlines,
  FilesInput,
  Finance,
  PaymentConditions,
} from '../components';

interface ICustomerSpecificationProps {
  viewMode: boolean;
  specification: IManagerCustomerSpecification | undefined;
  checkRequired: boolean;
  orderProcessingStarted: boolean;
  businessRequestId: number;
  isQuickDeal: boolean;
  shippingIncluded: boolean;
}

const mode = 'customer';

export const CustomerSpecification: FC<ICustomerSpecificationProps> = ({
  viewMode,
  specification,
  checkRequired,
  businessRequestId,
  isQuickDeal,
  orderProcessingStarted,
  shippingIncluded,
}) => {
  const { pathname } = useLocation();

  return (
    <>
      {specification?.customerData && (
        <InfoRow
          label="Заказчик"
          value={
            <Link
              state={{ path: pathname }}
              component={RouterLink}
              to={`/companies/customers/${specification.customerData.organizationId}`}>
              {specification.customerData.organizationName}
            </Link>
          }
        />
      )}

      <Finance
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
        shippingIncluded={shippingIncluded}
      />

      <Deadlines viewMode={viewMode} checkRequired={checkRequired} />

      <PaymentConditions viewMode={viewMode} checkRequired={checkRequired} />

      {!orderProcessingStarted && (
        <OpenChat
          entityType={ConversationEntityType.BUSINESS_REQUEST}
          entityId={businessRequestId}
          mode="manager"
          sx={{ marginBottom: '24px' }}
        />
      )}

      <FilesInput
        specification={specification}
        viewMode={viewMode}
        checkRequired={checkRequired}
        mode={mode}
        isQuickDeal={isQuickDeal}
      />
    </>
  );
};
