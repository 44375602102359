import { Components } from '@mui/material/styles/components';

export const MuiIconButton: Components['MuiIconButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      padding: 0,
      borderRadius: '8px',
      '&.MuiIconButton-edgeEnd': {
        padding: '14px',
      },
    },
  },
};
