import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { ICommonCompany, ICompany, IShippingAddress } from 'src/models';
import {
  IOrganizationBankDetails,
  IOrganizationDetails,
} from 'src/models/company';
import { TGetContractorsKanbanResponse } from 'src/models/contractor';

export type TContractorListFilter = {
  searchQueries: Array<string>;
  filter: {
    organizationStatusCodes: Array<string>;
  };
};

export const contractorApi = createApi({
  reducerPath: 'contractorApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Contractor'],
  endpoints: (builder) => ({
    getById: builder.query<ICompany, number>({
      providesTags: ['Contractor'],
      query: (id) => ({
        url: `organizations/api/v2/organization/${id}/contractor`,
      }),
    }),
    addContractor: builder.mutation<
      { id: number },
      {
        name: string;
        inn?: string;
      }
    >({
      query: (body) => ({
        url: `/organizations/api/v1/organization/simple/contractor`,
        method: 'POST',
        body,
      }),
    }),
    update: builder.mutation<ICompany, ICommonCompany>({
      query: ({ id, ...body }) => ({
        url: `organizations/api/v2/organization/${id}/contractor`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData('getById', id, () => resultData)
          );
        } catch {
          // ignore
        }
      },
    }),
    updateTechnicalAuditDate: builder.mutation<
      void,
      { id: number; technicalAuditDate: string | null }
    >({
      query: (body) => ({
        url: `organizations/api/v1/organization/${body.id}/contractor/technical-audit-date`,
        method: 'PATCH',
        body: {
          technicalAuditDate: body.technicalAuditDate,
        },
      }),
      async onQueryStarted(
        { id, technicalAuditDate },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData('getById', id, (draft) => {
              draft.technicalAuditDate = technicalAuditDate;
            })
          );
        } catch {
          // ignore
        }
      },
    }),
    updateTechnicalOpportunities: builder.mutation<
      void,
      {
        id: number;
        technicalOpportunities: ICompany['technicalOpportunitiesCategories'];
      }
    >({
      query: (body) => ({
        url: `organizations/api/v1/organization/${body.id}/contractor/technical-opportunities-categories`,
        method: 'PATCH',
        body: body.technicalOpportunities,
      }),
      async onQueryStarted(
        { id, technicalOpportunities },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData('getById', id, (draft) => {
              draft.technicalOpportunitiesCategories = technicalOpportunities;
            })
          );
        } catch {
          // ignore
        }
      },
    }),
    getShippingAddress: builder.query<IShippingAddress[], number>({
      query: (companyId) => ({
        url: `organizations/api/v1/organization/${companyId}/contactor/shipping`,
      }),
      transformResponse: (response: Array<IShippingAddress>) =>
        response.sort((a) => (a.type === 'PLAIN' ? 1 : -1)),
    }),
    createShippingAddress: builder.mutation<
      IShippingAddress[],
      { companyId: number; body: Omit<IShippingAddress, 'id'> }
    >({
      query: ({ companyId, body }) => ({
        url: `organizations/api/v1/organization/${companyId}/contactor/shipping`,
        method: 'POST',
        body,
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData(
              'getShippingAddress',
              companyId,
              () => [...resultData].sort((a) => (a.type === 'PLAIN' ? 1 : -1))
            )
          );
        } catch {
          throw new Error();
        }
      },
    }),
    updateShippingAddress: builder.mutation<
      IShippingAddress[],
      { companyId: number; body: IShippingAddress }
    >({
      query: ({ companyId, body }) => ({
        url: `organizations/api/v1/organization/${companyId}/contactor/shipping/${body.id}`,
        method: 'PUT',
        body,
      }),
      async onQueryStarted({ companyId }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData(
              'getShippingAddress',
              companyId,
              () => [...resultData].sort((a) => (a.type === 'PLAIN' ? 1 : -1))
            )
          );
        } catch (e) {
          throw new Error();
        }
      },
    }),
    deleteShippingAddress: builder.mutation<
      void,
      { companyId: number; addressId: number }
    >({
      query: ({ companyId, addressId }) => ({
        url: `/organizations/api/v1/organization/${companyId}/contactor/shipping/${addressId}`,
        method: 'DELETE',
      }),
      async onQueryStarted(
        { companyId, addressId },
        { dispatch, queryFulfilled }
      ) {
        try {
          await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData(
              'getShippingAddress',
              companyId,
              (draft) => draft.filter((item) => item.id !== addressId)
            )
          );
        } catch (e) {
          throw new Error();
        }
      },
    }),

    updateCompanyDetails: builder.mutation<
      IOrganizationDetails,
      Pick<ICommonCompany, 'id' | 'organizationDetails' | 'inn'>
    >({
      invalidatesTags: ['Contractor'],
      query: ({ id, ...body }) => ({
        url: `organizations/api/v2/organization/${id}/contractor/details`,
        method: 'PUT',
        body: { inn: body.inn, organizationDetails: body.organizationDetails },
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData('getById', id, (data) => ({
              ...data,
              ...resultData,
            }))
          );
        } catch {
          throw new Error();
        }
      },
    }),

    updateBankDetails: builder.mutation<
      IOrganizationBankDetails,
      Pick<ICommonCompany, 'id' | 'organizationBankDetails'>
    >({
      query: ({ id, ...body }) => ({
        url: `organizations/api/v2/organization/${id}/contractor/bank-details`,
        method: 'PUT',
        body: body.organizationBankDetails,
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        try {
          const { data: resultData } = await queryFulfilled;
          dispatch(
            contractorApi.util.updateQueryData('getById', id, (data) => ({
              ...data,
              organizationBankDetails: {
                ...resultData,
              },
            }))
          );
        } catch {
          throw new Error();
        }
      },
    }),
    getContractorsKanban: builder.mutation<
      TGetContractorsKanbanResponse,
      TContractorListFilter
    >({
      query: (body) => ({
        url: `organizations/api/v1/kanban/contractors`,
        method: 'POST',
        body,
      }),
    }),
  }),
});

export const {
  useGetByIdQuery,
  useLazyGetByIdQuery,
  useUpdateMutation,
  useUpdateTechnicalAuditDateMutation,
  useUpdateTechnicalOpportunitiesMutation,
  useGetShippingAddressQuery,
  useCreateShippingAddressMutation,
  useUpdateShippingAddressMutation,
  useDeleteShippingAddressMutation,
  useAddContractorMutation,
  useUpdateCompanyDetailsMutation,
  useUpdateBankDetailsMutation,
  useGetContractorsKanbanMutation,
} = contractorApi;
