import { useOutletContext } from 'react-router-dom';
import {
  IBusinessRequestCustomerInfo,
  IContractorBusinessRequest,
  IContractorRequest,
  ICustomerBusinessRequest,
  IManagerBusinessRequest,
  IStatusHistory,
} from 'src/models';

export const useBusinessRequestContext = () =>
  useOutletContext<{
    businessRequest: IManagerBusinessRequest;
    statusHistory: IStatusHistory;
  }>();

export const useCustomerRequestContext = () =>
  useOutletContext<{
    businessRequest: ICustomerBusinessRequest;
  }>();

export const useContractorRequestContext = () =>
  useOutletContext<{
    businessRequest: IContractorRequest;
    statusHistory: IStatusHistory;
  }>();

export const useBusinessRequestCustomerInfoContext = () =>
  useOutletContext<{
    businessRequest: IBusinessRequestCustomerInfo;
  }>();

export const useContractorBusinessRequestContext = () =>
  useOutletContext<{
    businessRequest: IContractorBusinessRequest;
  }>();
