import { ContractStatuses } from 'src/models';
import { StatusNoSign, StatusSign } from 'src/assets/icons';

export const ContractStatusIcons: Record<ContractStatuses, any> = {
  DRAFT_UPLOAD: StatusNoSign,
  DRAFT_APPROVAL: StatusNoSign,
  FINAL_CONTRACT_CREATION: StatusNoSign,
  CONTRACT_SIGNED: StatusSign,
};

export const ContractStatusTooltip: Record<ContractStatuses, string> = {
  DRAFT_UPLOAD: 'Договор не подписан',
  DRAFT_APPROVAL: 'Договор не подписан',
  FINAL_CONTRACT_CREATION: 'Договор не подписан',
  CONTRACT_SIGNED: 'Договор подписан',
};
