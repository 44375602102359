import { FC, useRef, useState } from 'react';
import { useAddNewCompanyRoleMutation } from 'src/api/company';
import {
  ConfirmDialog,
  Dialog,
  DialogContent,
  DialogTitle,
  HintBar,
  TDialogProps,
  Typography,
} from 'src/components/ui';
import { CompanyTypes } from 'src/models';
import { getErrorMessage } from 'src/store/utils';

interface IAddRoleModalProps extends Pick<TDialogProps, 'close' | 'open'> {
  newRole: CompanyTypes | null;
  organizationId: number;
}

export const AddRoleModal: FC<IAddRoleModalProps> = ({
  open,
  newRole,
  organizationId,
  close,
}) => {
  const newRoleRef = useRef<CompanyTypes | null>(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenSuccess, setIsOpenSuccess] = useState(false);

  const [addNewCompanyRole] = useAddNewCompanyRoleMutation();

  const onClose = () => {
    setError('');
    close?.();
  };

  const closeSuccessDialog = () => {
    setIsOpenSuccess(false);
    newRoleRef.current = null;
  };

  const confirmNewRole = async () => {
    setError('');
    setIsLoading(true);

    try {
      await addNewCompanyRole({
        organizationId,
        organizationRole: newRole!,
      }).unwrap();
      onClose();
      setIsOpenSuccess(true);
      newRoleRef.current = newRole;
    } catch (err) {
      setError(getErrorMessage(err, 'Произошла ошибка при добавлении роли'));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ConfirmDialog
        maxWidth={600}
        open={open}
        close={onClose}
        title="Подтвердите включение новой роли"
        confirmText="Подтвердить"
        cancelText="Отменить"
        settingsBtn={{ loading: isLoading }}
        onConfirm={confirmNewRole}>
        <HintBar sx={{ mb: 2 }}>
          <Typography variant="body2" lineHeight="150%">
            {newRole === CompanyTypes.CONTRACTOR
              ? 'Внимание после подтверждения вашей компании будет добавлена новая роль “Исполнителя”, вы сможете не только создавать заказы, но и брать их для выполнения.'
              : 'Внимание после подтверждения вашей компании будет добавлена новая роль “Заказчик”, вы сможете не только брать заказы в работу, но и создавать новые для других исполнителей.'}
          </Typography>
        </HintBar>
        <Typography>
          Переключение новой роли будет доступно для всех пользователей вашей
          компании по следующему пути:
          <br />
          “Шапка” сервиса {'>'} Меню пользователя {'>'} Сменить аккаунт
        </Typography>
        {!!error && (
          <Typography mt={0.5} variant="body2" color="error">
            {error}
          </Typography>
        )}
      </ConfirmDialog>

      <Dialog open={isOpenSuccess} close={closeSuccessDialog}>
        <DialogTitle>Роль добавлена</DialogTitle>
        <DialogContent>
          <Typography variant="body2" lineHeight="150%" mb={1.25}>
            {newRoleRef.current === CompanyTypes.CONTRACTOR
              ? 'Вашей компании успешно добавлена роль “Исполнитель”. Можете воспользоваться “Сменой аккаунта” и начать брать в работу заказы.'
              : 'Вашей компании успешно добавлена роль “Заказчик”. Можете воспользоваться “Сменой аккаунта” и начать работу по созданию заявок. '}
          </Typography>
        </DialogContent>
      </Dialog>
    </>
  );
};
