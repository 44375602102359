import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { authReducer, logout } from 'src/store/auth';
import {
  authApi,
  userApi,
  chatApi,
  contractApi,
  contractorApi,
  customerApi,
  companyApi,
  daDataApi,
  documentApi,
  dictionaryApi,
  dashboardsApi,
  contactsApi,
  businessRequestApi,
  adminCompanyApi,
  adminEventsApi,
  adminUserApi,
  adminContactsApi,
  adminRefusalApi,
  adminTechnicalOpportunities,
  adminNotificationsApi,
  adminRequestsApi,
  adminEmailSenderApi,
  adminTemplatesApi,
  contractorCommercialOfferApi,
  customerCommercialOfferApi,
  customerSpecificationApi,
  contractorSpecificationApi,
  reportApi,
  managerCommercialOfferApi,
  managerSpecificationApi,
  newsApi,
  managerAuctionApi,
  adminShippingApi,
  adminPaymentsApi,
  adminQueueApi,
  notificationsApi,
  communicationsAggregatorApi,
  emailFileStorageApi,
  fileStorageApi,
  apiBase,
  adminEmailsApi,
  adminImportsApi,
  digestApi,
  adminGroupsApi,
} from 'src/api';
import { draftFormsReducer } from 'src/store/draft-forms/draft-forms-slice';
import { kanbanReducer } from 'src/store/kanban/kanban-slice';
import { userReducer } from 'src/store/user/user-slice';
import {
  emailsReducer,
  telephonyReducer,
  allCommunicationsReducer,
} from 'src/store/communications';
import { businessEventApi } from '../api/business-event';
import {
  contractorShippingApi,
  managerShippingApi,
} from '../api/business-request/shipping';
import { chatReducer } from './chat';
import { commercialOfferReducer } from './commercial-offer';
import { notificationsReducer } from './notifications';
import { requestNotificationsReducer } from './request-notifications';
import { auctionReducer } from './auction';
import { requestsContractorReducer, requestsManagerReducer } from './requests';
import { contractorsReducer } from './contractors/contractors-slice';
import { requestsCustomerReducer } from './requests/requests-customer-slice';
import { pageRequestParamsReducer } from './page-request-params';

const combinedReducer = combineReducers({
  [apiBase.reducerPath]: apiBase.reducer,
  [newsApi.reducerPath]: newsApi.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [businessEventApi.reducerPath]: businessEventApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [chatApi.reducerPath]: chatApi.reducer,
  [notificationsApi.reducerPath]: notificationsApi.reducer,
  [contractApi.reducerPath]: contractApi.reducer,
  [contractorApi.reducerPath]: contractorApi.reducer,
  [customerApi.reducerPath]: customerApi.reducer,
  [companyApi.reducerPath]: companyApi.reducer,
  [daDataApi.reducerPath]: daDataApi.reducer,
  [contactsApi.reducerPath]: contactsApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [dictionaryApi.reducerPath]: dictionaryApi.reducer,
  [dashboardsApi.reducerPath]: dashboardsApi.reducer,
  [businessRequestApi.reducerPath]: businessRequestApi.reducer,
  [adminEventsApi.reducerPath]: adminEventsApi.reducer,
  [adminEmailsApi.reducerPath]: adminEmailsApi.reducer,
  [adminCompanyApi.reducerPath]: adminCompanyApi.reducer,
  [adminUserApi.reducerPath]: adminUserApi.reducer,
  [adminContactsApi.reducerPath]: adminContactsApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [adminRefusalApi.reducerPath]: adminRefusalApi.reducer,
  [adminTechnicalOpportunities.reducerPath]:
    adminTechnicalOpportunities.reducer,
  [adminNotificationsApi.reducerPath]: adminNotificationsApi.reducer,
  [adminRequestsApi.reducerPath]: adminRequestsApi.reducer,
  [adminImportsApi.reducerPath]: adminImportsApi.reducer,
  [adminEmailSenderApi.reducerPath]: adminEmailSenderApi.reducer,
  [adminTemplatesApi.reducerPath]: adminTemplatesApi.reducer,
  [contractorCommercialOfferApi.reducerPath]:
    contractorCommercialOfferApi.reducer,
  [customerCommercialOfferApi.reducerPath]: customerCommercialOfferApi.reducer,
  [managerCommercialOfferApi.reducerPath]: managerCommercialOfferApi.reducer,
  [customerSpecificationApi.reducerPath]: customerSpecificationApi.reducer,
  [managerSpecificationApi.reducerPath]: managerSpecificationApi.reducer,
  [contractorSpecificationApi.reducerPath]: contractorSpecificationApi.reducer,
  [contractorShippingApi.reducerPath]: contractorShippingApi.reducer,
  [managerShippingApi.reducerPath]: managerShippingApi.reducer,
  [managerAuctionApi.reducerPath]: managerAuctionApi.reducer,
  [adminShippingApi.reducerPath]: adminShippingApi.reducer,
  [adminPaymentsApi.reducerPath]: adminPaymentsApi.reducer,
  [adminQueueApi.reducerPath]: adminQueueApi.reducer,
  [digestApi.reducerPath]: digestApi.reducer,
  [adminGroupsApi.reducerPath]: adminGroupsApi.reducer,
  auth: authReducer,
  kanban: kanbanReducer,
  draftForms: draftFormsReducer,
  user: userReducer,
  chat: chatReducer,
  notifications: notificationsReducer,
  commercialOffer: commercialOfferReducer,
  auction: auctionReducer,
  requestNotifications: requestNotificationsReducer,
  contractors: contractorsReducer,
  requestsManager: requestsManagerReducer,
  requestsContractor: requestsContractorReducer,
  requestsCustomer: requestsCustomerReducer,
  [emailFileStorageApi.reducerPath]: emailFileStorageApi.reducer,
  [fileStorageApi.reducerPath]: fileStorageApi.reducer,
  [communicationsAggregatorApi.reducerPath]:
    communicationsAggregatorApi.reducer,
  emails: emailsReducer,
  telephony: telephonyReducer,
  allCommunications: allCommunicationsReducer,
  pageRequestParams: pageRequestParamsReducer,
});

type CombinedReducersState = ReturnType<typeof combinedReducer>;

export const store = configureStore({
  reducer: (rootState: CombinedReducersState | undefined, action) => {
    const state =
      rootState && logout.match(action)
        ? ({ auth: { ...rootState.auth } } as CombinedReducersState)
        : rootState;

    return combinedReducer(state, action);
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiBase.middleware,
      authApi.middleware,
      adminImportsApi.middleware,
      businessEventApi.middleware,
      userApi.middleware,
      chatApi.middleware,
      notificationsApi.middleware,
      contractApi.middleware,
      contractorApi.middleware,
      customerApi.middleware,
      companyApi.middleware,
      daDataApi.middleware,
      contactsApi.middleware,
      documentApi.middleware,
      dictionaryApi.middleware,
      dashboardsApi.middleware,
      businessRequestApi.middleware,
      reportApi.middleware,
      adminCompanyApi.middleware,
      adminUserApi.middleware,
      adminContactsApi.middleware,
      adminRefusalApi.middleware,
      adminEventsApi.middleware,
      adminEmailsApi.middleware,
      adminTechnicalOpportunities.middleware,
      adminNotificationsApi.middleware,
      adminRequestsApi.middleware,
      adminEmailSenderApi.middleware,
      adminTemplatesApi.middleware,
      newsApi.middleware,
      adminShippingApi.middleware,
      adminPaymentsApi.middleware,
      adminQueueApi.middleware,
      communicationsAggregatorApi.middleware,
      emailFileStorageApi.middleware,
      fileStorageApi.middleware,
      digestApi.middleware,
      adminGroupsApi.middleware
    ),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
