import { Components } from '@mui/material/styles/components';

export const MuiOutlinedInput: Components['MuiOutlinedInput'] = {
  styleOverrides: {
    root: {
      '&:not(.Mui-focused):not(.Mui-error):not(.Mui-disabled)': {
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#9FA8B3',
          },
        },
      },
      '&.Mui-disabled': {
        backgroundColor: 'rgba(0, 47, 108, 0.04)',
      },
    },
    notchedOutline: {
      borderColor: '#CACBCC',
    },
  },
  variants: [
    {
      props: { size: 'medium' },
      style: ({ theme }) => ({
        lineHeight: '20px',
        '& input': {
          height: '52px',
          paddingTop: 0,
          paddingBottom: 0,

          [theme.breakpoints.down('md')]: {
            height: '44px',
          },
        },
      }),
    },
  ],
};
