import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import {
  IEmailMessage,
  ITelephonyCall,
  INote,
} from 'src/models/communications';

interface IInitialState {
  currentCommunication: ITelephonyCall | IEmailMessage | INote | null;
  currentApplicationNumber: string | null;
}

const initialState: IInitialState = {
  currentCommunication: null,
  currentApplicationNumber: null,
};

const allCommunicationsSlice = createSlice({
  name: 'all-communications',
  initialState,
  reducers: {
    setCurrentCommunication(
      state,
      action: PayloadAction<ITelephonyCall | IEmailMessage | INote | null>
    ) {
      const { payload } = action;
      state.currentCommunication = payload;
    },
    setCurrentApplicationNumber(state, action: PayloadAction<string | null>) {
      const { payload } = action;
      state.currentApplicationNumber = payload;
    },
  },
});

export const { setCurrentCommunication, setCurrentApplicationNumber } =
  allCommunicationsSlice.actions;
export const allCommunicationsReducer = allCommunicationsSlice.reducer;
