import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';

export type TOrganizationByInn = {
  inn: string;
  okpo: string;
  okved: string;
  ogrn: string;
  kpp: string;
  organizationName: string;
  organizationFullName: string;
  legalAddress: string;
  region: string;
  city: string;
};

export type TOrganizationByName = {
  organizationName: string;
  inn: string;
};

export type TOrganizationByNameParams = {
  organizationName: string;
};

type TBankByBic = {
  bic: string;
  correspondentAccount: string;
  bankName: string;
  swift: string;
};

export type TAddress = {
  address: string;
  region?: string;
  city?: string;
};

export type TOrganization = {
  organizationName: string;
  inn: string;
  address: string;
  active: boolean;
};

export type TBank = {
  bankName: string;
  bic: string;
  address: string;
  active: boolean;
};

export const daDataApi = createApi({
  reducerPath: 'daDataApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getOrganizationByInn: builder.query<TOrganizationByInn, string>({
      query: (inn) => `dadata-adapter/api/v1/organization/${inn}`,
    }),
    getOrganizationByName: builder.query<
      TOrganizationByName[],
      TOrganizationByNameParams
    >({
      query: (body) => ({
        url: 'dadata-adapter/api/v1/organization/suggestByOrganizationName',
        method: 'POST',
        body,
      }),
    }),
    getBankByBic: builder.query<TBankByBic, string>({
      query: (bic) => `dadata-adapter/api/v1/bank/${bic}`,
    }),
    getAddresses: builder.query<TAddress[], string>({
      query: (address) => ({
        url: 'dadata-adapter/api/v2/addresses',
        params: { address },
      }),
    }),
    getOrganizationsListByInn: builder.query<TOrganization[], string>({
      query: (inn) => `dadata-adapter/api/v1/organization/suggestByInn/${inn}`,
    }),
    getBanksListByBic: builder.query<TBank[], string>({
      query: (bic) => `dadata-adapter/api/v1/bank/suggestByBic/${bic}`,
    }),
  }),
});

export const {
  useLazyGetAddressesQuery,
  useLazyGetBankByBicQuery,
  useLazyGetOrganizationByInnQuery,
  useLazyGetOrganizationsListByInnQuery,
  useLazyGetBanksListByBicQuery,
  useLazyGetOrganizationByNameQuery,
} = daDataApi;
