import { ReactNode } from 'react';
import { ROLES } from 'src/constants';
import { IManagerBusinessRequest } from 'src/models';
import { InvokeExpertStage } from 'src/models/bids-details';
import { AuthService } from 'src/services';

export const filterSpecificRoleTabs = (
  items: {
    path: string;
    title: ReactNode;
  }[],
  businessRequest: IManagerBusinessRequest
): { path: string; title: ReactNode }[] => {
  const filteredItems = [];
  const isLogistics = AuthService.currentRole! === ROLES.LOGISTIC;

  if (
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.REQUEST_FORMULATION
    ] ||
    businessRequest.assignedSpecificRoles[InvokeExpertStage.REQUEST_AGREEMENT]
  ) {
    filteredItems.push(
      items.find((item) => item.path.includes('customer-request'))!
    );
  }

  if (
    isLogistics ||
    businessRequest.assignedSpecificRoles[InvokeExpertStage.QUOTATION_AGREEMENT]
  ) {
    filteredItems.push(
      items.find((item) => item.path.includes('commercial-offers'))!
    );
  }

  if (
    isLogistics ||
    businessRequest.assignedSpecificRoles[
      InvokeExpertStage.SPECIFICATION_AGREEMENT
    ]
  ) {
    filteredItems.push(
      items.find((item) => item.path.includes('specifications'))!
    );
  }

  if (
    [
      ROLES.BACK_OFFICE,
      ROLES.OFFICE_MANAGER,
      ROLES.FINANCIAL_MANAGER,
      ROLES.LAWYER,
    ].includes(AuthService.currentRole!)
  ) {
    filteredItems.push(items.find((item) => item.path.includes('payment'))!);
    filteredItems.push(items.find((item) => item.path.includes('shipping'))!);
  }

  return filteredItems;
};
