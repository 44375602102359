import { FC, ReactNode } from 'react';
import { Drawer as MuiDrawer } from '@mui/material';
import { DrawerProps } from '@mui/material/Drawer/Drawer';
import { IconButton, Typography } from 'src/components/ui';
import { CloseIcon } from 'src/assets/icons';
import styles from './drawer.module.scss';

export type TDrawerProps = DrawerProps & {
  title?: ReactNode;
  close?: () => void;
};

export const Drawer: FC<TDrawerProps> = ({
  title,
  close,
  children,
  ...props
}) => (
  <MuiDrawer {...props}>
    <div className={styles.header}>
      {title && <Typography variant="h2">{title}</Typography>}
      <IconButton aria-label="close" onClick={close}>
        <CloseIcon />
      </IconButton>
    </div>
    <div className={styles.content}>{children}</div>
  </MuiDrawer>
);
