import {
  InvokeExpertStage,
  ISpecificRole,
  LogisticsCalculationDto,
} from 'src/models/bids-details';
import { TCargoParametersFull } from '../cargo-parameters';
import {
  LogisticsCalculationRequestResponse,
  OrganizationStatus,
} from '../commercial-offer';
import { TDocument } from '../document';

export enum ManagerContractorSpecificationStatus {
  DRAFT = 'DRAFT',
  PRELIMINARY_VERSION_SENT = 'PRELIMINARY_VERSION_SENT',
  DELETED = 'DELETED',
  SIGNED_SCAN_RECEIVED = 'SIGNED_SCAN_RECEIVED',
  FINAL_VERSION_SENT = 'FINAL_VERSION_SENT',
}

export interface IManagerContractorSpecification {
  specificationId: number;
  contractorRequestId?: number;
  quickDeal: boolean;

  contractorData: {
    organizationId: number;
    organizationName: string;
  };
  cost: number | null;
  supplyDuration: number | null;
  paymentPeriodProlongation: number | null;
  paymentConditions: string;

  status: {
    code: ManagerContractorSpecificationStatus;
    createdAt: string | null;
    displayName: string;
  };

  preliminaryVersionSentAt: string | null;
  signedScanReceivedAt: string | null;
  finalVersionSentAt: string | null;
  createdAt: string;
  draftLastModifiedAt: string;

  documents: {
    SPECIFICATION_SIGNED_SCAN?: TDocument[];
    SPECIFICATION_PRELIMINARY_VERSION?: TDocument[];
    SPECIFICATION_FINAL_VERSION?: TDocument[];
  };

  shippingIncluded: boolean;

  shipping: {
    deliveryAddress: string;
    shippingAddress: string;

    calculatedShippingCost: number | null;
    manualShippingCost: number | null;

    cargoDescription: TCargoParametersFull | null;
  };

  winner: {
    comment: string;
    winnerCriteria: string[];
    supplySumVisibleForOtherContractors: boolean;
  } | null;
}

export enum ManagerCustomerSpecificationStatus {
  DRAFT = 'DRAFT',
  PRELIMINARY_VERSION_SENT = 'PRELIMINARY_VERSION_SENT',
  DELETED = 'DELETED',
  SIGNED_SCAN_RECEIVED = 'SIGNED_SCAN_RECEIVED',
  FINAL_VERSION_SENT = 'FINAL_VERSION_SENT',
}

export interface IManagerCustomerSpecification {
  specificationId: number;
  businessApplicationId: number;
  costByContractor: number | null;
  costForCustomer: number | null;
  income: number | null;
  supplyDuration: number | null;
  paymentPeriodProlongation: number | null;
  paymentConditions: string;
  quickDeal: boolean;
  shippingIncluded: boolean;
  shippingCost: number | null;

  assignedSpecificRoles: Record<InvokeExpertStage, ISpecificRole[] | undefined>;

  customerData: {
    organizationId: number;
    organizationName: string;
  };

  status: {
    code: ManagerCustomerSpecificationStatus;
    createdAt: string | null;
    displayName: string;
  };

  logisticsCalculationRequest?: LogisticsCalculationRequestResponse;
  logisticsCalculationDto?: LogisticsCalculationDto;

  preliminaryVersionSentAt: string | null;
  signedScanReceivedAt: string | null;
  finalVersionSentAt: string | null;
  createdAt: string | null;
  draftLastModifiedAt: string | null;

  documents: {
    SPECIFICATION_SIGNED_SCAN?: TDocument[];
    SPECIFICATION_PRELIMINARY_VERSION?: TDocument[];
    SPECIFICATION_FINAL_VERSION?: TDocument[];
  };
}

export interface IManagerContractorSpecificationAvailableContractor {
  organizationId: number;
  organizationName: string;
  organizationFullName?: string;

  organizationStatus?: {
    id: number;
    code: OrganizationStatus;
    statusName: string;
    description: string;
  };

  bestCommercialOffer?: {
    commercialOfferId: number;
    costForContractor: number;
  } | null;

  shippingIncluded: boolean;
  supplyDuration?: number;
  paymentConditions?: string;

  shipping: {
    shippingAddress: string;

    calculatedShippingCost: number | null;
    manualShippingCost: number | null;
  };
}

export interface IManagerContractorWinnerCriterion {
  id: number;
  name: string;
}
