import { FC, HTMLAttributes, ReactNode } from 'react';
import { Card, Typography } from 'src/components/ui';
import { Warning } from 'src/assets/icons';
import classNames from 'classnames';
import styles from './result.module.scss';

type TResultProps = {
  status?: 'warning' | 'info';
  title?: ReactNode;
  subtitle?: ReactNode;
  icon?: ReactNode;
  fullWidth?: boolean;
  noContentPadding?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const iconsByStatus = {
  warning: <Warning />,
  info: <Warning />,
};

export const Result: FC<TResultProps> = ({
  status = 'warning',
  title,
  subtitle,
  icon = iconsByStatus[status],
  fullWidth,
  className,
  noContentPadding,
  ...props
}) => (
  <div
    {...props}
    className={classNames(
      !fullWidth && styles.wrapper,
      noContentPadding && styles.noContentPadding
    )}>
    <Card className={classNames(styles.card, className)}>
      {icon && <div className={styles.cardIcon}>{icon}</div>}
      {title && <Typography variant="h2">{title}</Typography>}
      {subtitle && (
        <Typography variant="h4" color="secondary" mt="8px">
          {subtitle}
        </Typography>
      )}
    </Card>
  </div>
);
