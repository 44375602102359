import { ContractDocumentTypes } from 'src/models/contract';
import { ROLES } from '../constants';

export type TDocument = {
  createdAt: string;
  createdByUser: {
    firstName: string;
    middleName: string;
    lastName: string;
  };
  id: number;
  name: string;
  sizeBytes: number;
  status: 'ACTIVE' | 'DELETED';
  isWatched?: boolean | null;
  primary?: boolean;
};

export enum DocumentTypes {
  ORGANIZATION_CARD = 'ORGANIZATION_CARD',
  PRESENTATION = 'PRESENTATION',
  CONFORMITY_CERTIFICATE = 'CONFORMITY_CERTIFICATE',
  REQUEST_FOR_CONTRACTORS = 'REQUEST_FOR_CONTRACTORS',
  NEWS_POSTS = 'NEWS_POSTS',
  COMMERCIAL_OFFER = 'COMMERCIAL_OFFER',
  SPECIFICATION_PRELIMINARY_VERSION = 'SPECIFICATION_PRELIMINARY_VERSION',
  SPECIFICATION_SIGNED_SCAN = 'SPECIFICATION_SIGNED_SCAN',
  SPECIFICATION_FINAL_VERSION = 'SPECIFICATION_FINAL_VERSION',
  MANAGER_PAYMENT_INVOICE = 'MANAGER_PAYMENT_INVOICE',
  CUSTOMER_PAYMENT_ORDER = 'CUSTOMER_PAYMENT_ORDER',
  MANAGER_PAYMENT_ORDER = 'MANAGER_PAYMENT_ORDER',
  CONTRACTOR_PAYMENT_INVOICE = 'CONTRACTOR_PAYMENT_INVOICE',
  SHIPPING_ACK = 'SHIPPING_ACK',
  DELIVERY_ACK = 'DELIVERY_ACK',
  BUSINESS_APPLICATION = 'BUSINESS_APPLICATION',
  REPORT_ALL_CONTRACTORS = 'REPORT_ALL_CONTRACTORS',
  EMAIL_ATTACHMENT = 'EMAIL_ATTACHMENT',
  LOGISTICS_CALCULATION_REQUEST = 'LOGISTICS_CALCULATION_REQUEST',
}

export enum DocumentEntityTypes {
  CONTRACTOR = 'CONTRACTOR',
  ORGANIZATION = 'ORGANIZATION',
  BUSINESS_REQUEST = 'BUSINESS_REQUEST',
  NEWS_POSTS = 'NEWS_POSTS',
  CONTRACTOR_COMMERCIAL_OFFER = 'CONTRACTOR_COMMERCIAL_OFFER',
  COMMERCIAL_OFFER_FOR_CUSTOMER = 'COMMERCIAL_OFFER_FOR_CUSTOMER',
  CUSTOMER_SPECIFICATION = 'CUSTOMER_SPECIFICATION',
  CONTRACTOR_SPECIFICATION = 'CONTRACTOR_SPECIFICATION',
  CUSTOMER_PAYMENT = 'CUSTOMER_PAYMENT',
  CONTRACTOR_PAYMENT = 'CONTRACTOR_PAYMENT',
  SHIPMENT = 'SHIPMENT',
  BUSINESS_APPLICATION = 'BUSINESS_APPLICATION',
  REPORTS = 'REPORTS',
  BUSINESS_REQUEST_ASSIGNMENT_ASSIGNED_ROLE = 'BUSINESS_REQUEST_ASSIGNMENT_ASSIGNED_ROLE',
}

export enum TTypeConfig {
  ARCHIVE = 'archive',
  OTHER_FILE_TYPES = 'otherFileTypes',
}

export type TConfigData = Record<
  TTypeConfig,
  {
    count: number | null;
    maxFileSizeBytes: number | null;
    extensions: Array<string>;
  }
>;

export type TDocumentConfig = Record<
  ROLES,
  Record<DocumentTypes | ContractDocumentTypes, TConfigData>
>;
