import classNames from 'classnames';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUpdateNameRequestByIdMutation } from 'src/api/business-request/business-request';
import { Pencil, Reload } from 'src/assets/icons';
import { Form, IconButton, Input, Typography } from 'src/components/ui';
import { FIELD_LENGTH, PERMISSIONS } from 'src/constants';
import { RolePermissionService } from 'src/services';
import { getErrorMessage } from 'src/store/utils';
import { required, maxLength } from 'src/utils/form-rules';

import { TVariantBusinessRequest } from 'src/models';
import { isTerminalStatus } from 'src/utils/business-request';
import styles from './name-request.module.scss';

interface INameRequestProps {
  businessRequest: TVariantBusinessRequest;
}

interface INameRequestForm {
  name: string;
}

export const NameRequest: FC<INameRequestProps> = ({ businessRequest }) => {
  const [editNameRequest, setEditNameRequest] = useState(false);
  const [updateNameRequestById] = useUpdateNameRequestByIdMutation();

  const isTerminalStatusRequest = isTerminalStatus(
    businessRequest.status?.code || '',
    businessRequest.orderProcessingStarted
  );

  const form = useForm<INameRequestForm>({
    defaultValues: {
      name: businessRequest.name ?? 'Название запроса',
    },
  });

  const handleClickEditNameRequest = () => {
    setEditNameRequest((prevState) => !prevState);
  };

  const handleSubmitForm = async (e: INameRequestForm) => {
    const { name: newName } = e;

    if (!newName || !form.formState.isDirty) {
      handleClickEditNameRequest();

      return;
    }

    try {
      const rest = await updateNameRequestById({
        id: businessRequest.businessRequestId,
        name: newName,
      }).unwrap();

      handleClickEditNameRequest();

      form.reset({ name: rest.name });
    } catch (error) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(
          error,
          'Произошла ошибка при сохранении данных'
        ),
      });
    }
  };

  const handleResetForm = () => {
    form.reset();
    handleClickEditNameRequest();
  };

  const isEditable =
    !isTerminalStatusRequest &&
    RolePermissionService.can(PERMISSIONS.EDIT_BUSINESS_REQUEST);

  const containerViewMaxWidth = isEditable ? 'calc(100% - 26px)' : '100%';

  return (
    <Form
      className={classNames(
        styles.container,
        editNameRequest && styles.editNameRequest
      )}
      form={form}
      footer={false}>
      <Form.Item
        name="name"
        viewMode={!editNameRequest}
        noMargin
        helperTextPlacement="right"
        rules={{
          required,
          maxLength: maxLength(FIELD_LENGTH.nameRequest),
        }}
        renderView={(value) => (
          <div className={styles.containerView}>
            <Typography
              fontSize="16px"
              fontWeight="600"
              style={{
                wordWrap: 'break-word',
                maxWidth: containerViewMaxWidth,
              }}>
              {value}
            </Typography>

            {isEditable && (
              <IconButton onClick={handleClickEditNameRequest}>
                <Pencil />
              </IconButton>
            )}
          </div>
        )}>
        <Input
          className={styles.input}
          autoFocus
          onBlur={form.handleSubmit(handleSubmitForm)}
          disableUnderline
          endAdornment={
            form.formState.isDirty ? (
              <IconButton
                onMouseDown={(e) => e.preventDefault()}
                onMouseUp={(e) => e.preventDefault()}
                onClick={handleResetForm}>
                <Reload />
              </IconButton>
            ) : (
              <IconButton>
                <Pencil />
              </IconButton>
            )
          }
        />
      </Form.Item>
    </Form>
  );
};
