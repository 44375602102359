import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { baseQueryWithReauth } from 'src/store/utils';
import { IUserGroup } from 'src/models';
import { IGroupUser } from 'src/models/admin';
import { TListResult } from '../models';

type TUserGroupListResult = IUserGroup[];

type TUserForGroupListResult = TListResult<IGroupUser>;

export interface IUsersForGroupQueryParams {
  searchValue: string;
  page: number;
  size: number;
  sort?: string;
}

interface IAddGroupParams {
  name: string;
  color: string;
  userIds: number[];
}

interface IEditGroupParams {
  userGroupId: number;
  name: string;
  color: string;
  userIds: number[];
}

interface IDeleteUserGroupParams {
  userGroupId: number;
}

interface IDeleteUserFromGroupParams {
  userGroupId: number;
  ids: number[];
}

export const adminGroupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Groups'],
  endpoints: (builder) => ({
    getGroups: builder.query<TUserGroupListResult, void>({
      query: () => ({
        url: 'users-service/api/v1/user-group',
        method: 'GET',
        params: {
          withUsers: true,
        },
      }),
      providesTags: ['Groups'],
    }),
    getUsersForGroup: builder.query<
      TUserForGroupListResult,
      IUsersForGroupQueryParams
    >({
      query: (params) => ({
        url: 'users-service/api/v1/user/search-for-adding-to-group',
        method: 'GET',
        params,
      }),
      providesTags: ['Groups'],
    }),
    addGroup: builder.mutation<void, IAddGroupParams>({
      query: (body) => ({
        url: 'users-service/api/v1/user-group',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
    editGroup: builder.mutation<void, IEditGroupParams>({
      query: ({ userGroupId, ...body }) => ({
        url: `users-service/api/v1/user-group/${userGroupId}`,
        method: 'PATCH',
        body,
        params: {
          withUsers: true,
        },
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteGroup: builder.mutation<void, IDeleteUserGroupParams>({
      query: ({ userGroupId }) => ({
        url: `users-service/api/v1/user-group/${userGroupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),

    getGroupById: builder.query<IUserGroup, string>({
      query: (userGroupId) => ({
        url: `users-service/api/v1/user-group/${userGroupId}`,
        method: 'GET',
        params: {
          withUsers: true,
        },
      }),
      providesTags: ['Groups'], // TODO: !
    }),
    deleteUserFromGroup: builder.mutation<void, IDeleteUserFromGroupParams>({
      query: ({ userGroupId, ...body }) => ({
        url: `users-service/api/v1/user-group/${userGroupId}/users`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Groups'],
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useLazyGetUsersForGroupQuery,
  useAddGroupMutation,
  useEditGroupMutation,
  useDeleteGroupMutation,

  useGetGroupByIdQuery,
  useDeleteUserFromGroupMutation,
} = adminGroupsApi;
