import { FC, MouseEvent, useRef } from 'react';
import { IconButton, OutlinedInput } from 'src/components/ui';
import { CloseIcon, SearchBtn } from 'src/assets/icons';
import classNames from 'classnames';
import { SubmitHandler, useForm } from 'react-hook-form';

import { SxProps, Theme } from '@mui/material';
import commonStyles from '../../filters.module.scss';
import styles from './search-form.module.scss';

type TFormInput = {
  searchQuery: string;
};

type TProps = {
  onSearch: (searchQuery: string) => void;
  handleClearFilters: () => void;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  isDisabled?: boolean;
  defaultInput?: boolean;
  countChips?: number;
  inputSx?: SxProps<Theme>;
};

export const SearchForm: FC<TProps> = ({
  onSearch,
  handleClearFilters,
  onClick,
  isDisabled,
  defaultInput,
  countChips,
  inputSx,
}) => {
  const clearBtn = useRef<any>(null);

  const { register, handleSubmit, reset, watch } = useForm<TFormInput>({
    defaultValues: {
      searchQuery: '',
    },
  });

  const query = watch('searchQuery');
  const canClear = (countChips && countChips > 0) || !!query;

  const handleClearForm = () => {
    reset();

    // TODO: Проверить, не сломалось ли что-нибудь? Если нет, то удалить проверку
    // if (countChips) {
    //   handleClearFilters();
    // }

    handleClearFilters();
  };

  const handleClickSearchForm = (e: MouseEvent<HTMLElement>) => {
    if (clearBtn.current && !clearBtn.current.contains(e.target)) {
      onClick?.(e);
    }
  };

  const onSubmit: SubmitHandler<TFormInput> = ({ searchQuery }) => {
    onSearch(searchQuery);

    if (defaultInput) {
      return;
    }

    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <OutlinedInput
        {...register('searchQuery')}
        disabled={!!isDisabled}
        onClick={handleClickSearchForm}
        className={classNames(
          commonStyles.inputSearch,
          styles.noBorder,
          isDisabled && styles.disable
        )}
        trimValue={false}
        type="text"
        placeholder="Поиск и фильтры"
        fullWidth
        autoComplete="off"
        startAdornment={
          <IconButton type="submit" aria-label="search">
            <SearchBtn />
          </IconButton>
        }
        endAdornment={
          <IconButton
            ref={clearBtn}
            onClick={handleClearForm}
            className={classNames(
              commonStyles.clearBtn,
              !canClear && commonStyles.hidden
            )}>
            <CloseIcon />
          </IconButton>
        }
        sx={inputSx}
      />
    </form>
  );
};
