import { FC, ReactNode, useState } from 'react';
import { ControllerProps } from 'react-hook-form';
import classNames from 'classnames';
import {
  useLazyDownloadArchiveQuery,
  useLazyDownloadDocumentQuery,
} from 'src/api/document';
import { Form, FileUpload, FileList, Button } from 'src/components/ui';
import { DocumentEntityTypes, DocumentTypes } from 'src/models';
import { BidsShare } from 'src/assets/icons';
import { TFile } from 'src/components/ui/file-upload';
import { ModalShareRequest } from './components';
import styles from './attach-files.module.scss';

interface IAttachFilesProps {
  isEditMode?: boolean;
  formItemName?: string;
  documentOptions: {
    entityType: DocumentEntityTypes;
    entityId: number;
    documentType: DocumentTypes;
  };
  showShareButton?: boolean;
  showDownloadButton?: boolean;
  uploadFilesTitle?: React.ReactNode;
  fullScreen?: boolean;
  rules?: ControllerProps['rules'];
  subtitleComponent?: ReactNode;
  fileListTitle?: string;
  extraTitle?: ReactNode;
  noMargin?: boolean;
  onChange?: (files: TFile[]) => void;
  showDownloadAllButton?: boolean;
  variant?: 'default' | 'compact';
}

export const AttachFiles: FC<IAttachFilesProps> = ({
  isEditMode,
  formItemName = 'files',
  documentOptions,
  showShareButton = false,
  showDownloadButton = true,
  uploadFilesTitle = 'Прикрепите файлы',
  fullScreen = true,
  rules,
  subtitleComponent,
  fileListTitle = 'Прикрепленные файлы',
  extraTitle,
  noMargin,
  onChange,
  showDownloadAllButton = true,
  variant = 'default',
}) => {
  const [isShowShareRequest, setIsShowShareRequest] = useState(false);
  const [downloadDocument] = useLazyDownloadDocumentQuery();
  const [downloadArchive] = useLazyDownloadArchiveQuery();
  const toggleShareRequest = () => {
    setIsShowShareRequest((prevState) => !prevState);
  };

  const handleDownloadDocument = async (id: number) => {
    try {
      await downloadDocument(id).unwrap();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDownloadAllDocument = () =>
    downloadArchive(documentOptions).unwrap();

  return (
    <>
      <div
        className={classNames(
          styles.container,
          noMargin && styles.containerNoMargin
        )}>
        <Form.Item
          noMargin
          renderView={(values) => (
            <FileList
              variant={variant}
              title={fileListTitle}
              subtitle={subtitleComponent}
              actions={
                extraTitle ||
                (values?.length && showShareButton ? (
                  <Button
                    aria-label="to share"
                    title="Поделиться"
                    variant="text"
                    onClick={toggleShareRequest}
                    startIcon={<BidsShare />}>
                    Поделиться
                  </Button>
                ) : null)
              }
              value={values}
              onDownload={handleDownloadDocument}
              onDownloadAll={
                showDownloadAllButton ? handleDownloadAllDocument : undefined
              }
            />
          )}
          viewMode={!isEditMode}
          name={formItemName}
          rules={rules}>
          <FileUpload
            variant={variant}
            onChange={onChange}
            title={uploadFilesTitle}
            fullScreen={fullScreen}
            subtitle={subtitleComponent}
            onDownload={handleDownloadDocument}
            removeMode="soft"
            documentType={documentOptions.documentType}
            showDownloadButton={showDownloadButton}
          />
        </Form.Item>
      </div>

      {showShareButton && isShowShareRequest && (
        <ModalShareRequest
          shareByEmail
          open={isShowShareRequest}
          toggleShow={toggleShareRequest}
          entityId={documentOptions.entityId}
          entityType={documentOptions.entityType}
        />
      )}
    </>
  );
};
