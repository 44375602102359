import { SimplePaletteColorOptions, PaletteColor } from '@mui/material';
import { Components } from '@mui/material/styles/components';
import { palette } from '../palette';

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    loading: true;
    inlineText: true;
    loadingText: true;
  }

  interface ButtonPropsColorOverrides {
    attention: true;
  }
}

export const MuiButton: Components['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      width: 'fit-content',
      minWidth: 'auto',
      padding: '14px 16px',
      boxShadow: 'none',
      '&:hover': {
        boxShadow: 'none',
      },
      whiteSpace: 'nowrap',
    },
    iconSizeMedium: {
      '& svg': {
        width: '16px',
        height: '16px',
      },
    },
    fullWidth: {
      width: '100%',
    },
  },
  variants: [
    {
      props: { size: 'large' },
      style: ({ theme }) => ({
        padding: '20px 16px',

        [theme.breakpoints.down('md')]: {
          padding: '14px',
        },
      }),
    },

    {
      props: { fullWidth: true, color: 'primary' },
      style: {
        fontSize: '16px',
        padding: '20px 16px',
      },
    },
    {
      props: { variant: 'text' },
      style: {
        userSelect: 'auto',
        padding: 0,
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },
        '& .MuiButton-startIcon': {
          marginLeft: 0,
        },
      },
    },
    {
      props: { variant: 'text', color: 'primary' },
      style: ({ theme }) => ({
        '&:hover': {
          color: theme.palette.primary.dark,
        },
      }),
    },
    {
      props: { variant: 'text', color: 'secondary' },
      style: ({ theme }) => ({
        '&:hover': {
          color: theme.palette.secondary.dark,
        },
      }),
    },
    {
      props: { variant: 'inlineText' },
      style: ({ theme }) => ({
        padding: 0,
        fontSize: 'inherit',
        fontWeight: 'inherit',
        lineHeight: 'inherit',
        display: 'inline',
        verticalAlign: 'initial',
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: 'transparent',
          color: theme.palette.primary.dark,
        },
      }),
    },
    {
      props: { color: 'secondary', variant: 'contained' },
      style: {
        backgroundColor: 'rgba(0, 47, 108, 0.06)',
        '&:hover': {
          backgroundColor: 'rgba(0, 47, 108, 0.11)',
        },
        '& .MuiButton-startIcon': {
          marginLeft: 0,
        },
      },
    },
    {
      props: { variant: 'loading' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.contrastText,
        backgroundColor: 'rgba(0, 47, 108, 0.06)',
        pointerEvents: 'none',
        cursor: 'default',
      }),
    },
    {
      props: { variant: 'loadingText' },
      style: ({ theme }) => ({
        color: theme.palette.secondary.contrastText,
        padding: 0,
        pointerEvents: 'none',
        cursor: 'default',
      }),
    },
    {
      props: { color: 'error', variant: 'contained' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.error.light,
        color: theme.palette.error.main,
        '&:hover': {
          backgroundColor: theme.palette.error.light,
        },
        '&:disabled': {
          backgroundColor: '#FFE6E6',
          path: {
            stroke: '#7A8694',
          },
        },
      }),
    },
    {
      props: { color: 'success', variant: 'contained' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.success.light,
        color: theme.palette.success.main,
        '&:hover': {
          backgroundColor: theme.palette.success.light,
        },
      }),
    },
    {
      props: { color: 'attention', variant: 'contained' },
      style: ({ theme }) => ({
        backgroundColor: theme.palette.error.main,
        color: theme.palette.common.white,
        '&:hover': {
          backgroundColor: theme.palette.error.dark,
        },
      }),
    },
    {
      props: { variant: 'outlined' },
      style: {
        '&:disabled': {
          backgroundColor: 'rgba(0, 47, 108, 0.06)',
          path: {
            stroke: '#7A8694',
          },
        },
      },
    },
  ],
};
