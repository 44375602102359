import { HelpExpertStatuses } from 'src/components/ui';
import { HelpExpertBarStage } from 'src/components/widgets/help-expert-bar/help-expert-bar';
import { ROLES } from 'src/constants';
import { InvokeExpertStatus, ISpecificRole } from 'src/models/bids-details';

export const mapExpertRoleToString = (
  expert: ISpecificRole,
  genitive?: boolean
): string => {
  if (expert.role === ROLES.TECH_EXPERT) {
    if (genitive) {
      return 'техэкспертом';
    }

    return 'техэксперт';
  }

  if (expert.role === ROLES.COMMERCIAL_DIRECTOR) {
    if (genitive) {
      return 'коммерческим директором';
    }

    return 'коммерческий директор';
  }

  if (expert.role === ROLES.PARTNER_RELATIONS_MANAGER) {
    if (genitive) {
      return 'руководителем по работе с партнерами';
    }

    return 'руководитель по работе с партнерами';
  }

  if (expert.role === ROLES.LOGISTIC) {
    if (genitive) {
      return 'логистом';
    }

    return 'логист';
  }

  if (expert.role === ROLES.GENERAL_MANAGER) {
    if (genitive) {
      return 'генеральным директором';
    }

    return 'генеральный директор';
  }

  if (expert.role === ROLES.FINANCIAL_MANAGER) {
    if (genitive) {
      return 'финансовым менеджером';
    }

    return 'финансовый менеджер';
  }

  if (expert.role === ROLES.BACK_OFFICE) {
    if (genitive) {
      return 'бэк офисом';
    }

    return 'бэк офис';
  }

  if (expert.role === ROLES.OFFICE_MANAGER) {
    if (genitive) {
      return 'офис менеджером';
    }

    return 'офис менеджер';
  }

  if (expert.role === ROLES.LAWYER) {
    if (genitive) {
      return 'юристом';
    }

    return 'юрист';
  }

  return 'Не определено';
};

export const mapExpertInfoToString = (
  expert: ISpecificRole,
  stage: HelpExpertBarStage
): string => {
  if (!expert.assignedManager) {
    return 'Ожидает расчета логистики';
  }

  if (expert.approvalStatus === InvokeExpertStatus.IN_REVIEW) {
    return `Ожидает согласования ${mapExpertRoleToString(expert, true)}: ${
      expert.assignedManager.firstName
    } ${expert.assignedManager.lastName}`;
  }

  if (expert.approvalStatus === InvokeExpertStatus.FORMING) {
    if (stage === HelpExpertBarStage.COMMERCIAL_OFFER) {
      return `Ожидает расчета логистики: ${expert.assignedManager.firstName} ${expert.assignedManager.lastName}`;
    }

    return `Ожидает формирование запроса ${mapExpertRoleToString(
      expert,
      true
    )}: ${expert.assignedManager.firstName} ${expert.assignedManager.lastName}`;
  }

  if (expert.approvalStatus === InvokeExpertStatus.FORMED) {
    if (stage === HelpExpertBarStage.COMMERCIAL_OFFER) {
      return `Расчет логистики: ${expert.assignedManager.firstName} ${expert.assignedManager.lastName}`;
    }

    return `Сформировал ${mapExpertRoleToString(expert)}: ${
      expert.assignedManager.firstName
    } ${expert.assignedManager.lastName}`;
  }

  if (expert.approvalStatus === InvokeExpertStatus.APPROVED) {
    return `Согласовал ${mapExpertRoleToString(expert)}: ${
      expert.assignedManager.firstName
    } ${expert.assignedManager.lastName}`;
  }

  if (expert.approvalStatus === InvokeExpertStatus.REJECTED) {
    return `Отклонил ${mapExpertRoleToString(expert)}: ${
      expert.assignedManager.firstName
    } ${expert.assignedManager.lastName}`;
  }

  return 'Не определено';
};

export const mapExpertInfoToHelpBarStatus = (
  expert: ISpecificRole | undefined
): HelpExpertStatuses | null => {
  if (!expert) {
    return null;
  }

  if (
    expert.approvalStatus === InvokeExpertStatus.IN_REVIEW ||
    expert.approvalStatus === InvokeExpertStatus.FORMING
  ) {
    return 'PENDING';
  }

  if (expert.approvalStatus === InvokeExpertStatus.REJECTED) {
    return 'REJECTED';
  }

  if (
    expert.approvalStatus === InvokeExpertStatus.APPROVED ||
    expert.approvalStatus === InvokeExpertStatus.FORMED
  ) {
    return 'APPROVED';
  }

  return null;
};
