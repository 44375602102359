import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { CompanyTypes } from 'src/models';
import { baseQueryWithReauth } from 'src/store/utils';
import { TContact } from 'src/components/pages/company/contacts/types';

export const adminContactsApi = createApi({
  reducerPath: 'adminContactsApi',
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    getContacts: builder.query<
      Array<TContact>,
      { companyType: CompanyTypes; idCompany: number }
    >({
      query: ({ companyType, idCompany }) =>
        `/organizations/api/v1/organization/${idCompany}/${companyType.toLowerCase()}/contact`,
    }),
  }),
});

export const { useGetContactsQuery } = adminContactsApi;
