import { useForm } from 'react-hook-form';
import { useUpdateCompanyMutation } from 'src/api/company';
import { ICompany } from 'src/models';
import { getErrorMessage } from 'src/store/utils';

interface ICompanyForm {
  amocrmId: null | number;
  organizationName: string;
  organizationFullName: string;
  websiteLink: string;
  organizationDescription: string;
}

export const useCompanyDetailsForm = ({
  companyData,
  onSuccess,
}: {
  companyData: ICompany;
  onSuccess?: VoidFunction;
}) => {
  const [updateCompanyDetails] = useUpdateCompanyMutation();

  const form = useForm<ICompanyForm>({
    defaultValues: {
      amocrmId: companyData.organizationDetails?.amocrmId || null,
      organizationName: companyData.organizationDetails?.organizationName || '',
      organizationFullName:
        companyData.organizationDetails?.organizationFullName || '',
      websiteLink: companyData.organizationDetails?.websiteLink || '',
      organizationDescription:
        companyData.organizationDetails?.organizationDescription || '',
    },
  });

  const handleSubmit = async (formData: ICompanyForm) => {
    try {
      await updateCompanyDetails({
        organizationId: companyData.id,
        body: {
          roles: companyData.roles,
          technicalOpportunitiesCategories:
            companyData.technicalOpportunitiesCategories,
          organizationBankDetails: companyData.organizationBankDetails || {},
          inn: companyData.inn,
          organizationDetails: {
            ...companyData.organizationDetails,
            ...formData,
            amocrmId: formData.amocrmId || undefined,
          },
          comment: companyData.comment || '',
        },
      }).unwrap();
      onSuccess?.();
    } catch (e) {
      form.setError('root.serverError', {
        type: 'custom',
        message: getErrorMessage(e, 'Произошла ошибка при обновлении компании'),
      });
    }
  };

  const resetForm = () => {
    form.reset({
      amocrmId: companyData.organizationDetails?.amocrmId || null,
      organizationFullName:
        companyData.organizationDetails?.organizationFullName || '',
      organizationName: companyData.organizationDetails?.organizationName || '',
      websiteLink: companyData.organizationDetails?.websiteLink || '',
    });
  };

  return { handleSubmit, resetForm, form };
};
