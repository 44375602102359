import { PERMISSIONS, ROLES } from 'src/constants';

export const ROLE_PERMISSIONS: { [key in ROLES]?: PERMISSIONS[] } = {
  [ROLES.KAM]: [
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.VIEW_MANAGER_BUSINESS_REQUEST_LIST,
    PERMISSIONS.EDIT_BUSINESS_REQUEST,
    PERMISSIONS.CREATE_REQUEST,
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.EDIT_MANAGER_ORDER,
    PERMISSIONS.SHARE_CONTRACTOR_REQUEST,
    PERMISSIONS.VIEW_REQUEST_NOTIFICATIONS,
    PERMISSIONS.VIEW_OPEN_CHAT_BUTTON,

    PERMISSIONS.VIEW_COMMUNICATION_PAGE,
    PERMISSIONS.VIEW_COMMUNICATION_CHAT_WIDGET,

    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE,
    PERMISSIONS.VIEW_AND_EDIT_THERE_IS_NO_BEST_KP_BUTTON,

    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.EDIT_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.EDIT_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.EDIT_COMPANY_CONTACTS,
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
  ],
  [ROLES.KAM_PLUS]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.VIEW_MANAGER_BUSINESS_REQUEST_LIST,
    PERMISSIONS.EDIT_BUSINESS_REQUEST,
    PERMISSIONS.CREATE_CONTRACTOR,
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.EDIT_SHIPPING_GROUP,
    PERMISSIONS.EDIT_PAYMENT_GROUP,
    PERMISSIONS.EDIT_MANAGER_ORDER,
    PERMISSIONS.SHARE_CONTRACTOR_REQUEST,
    PERMISSIONS.VIEW_REQUEST_NOTIFICATIONS,
    PERMISSIONS.VIEW_OPEN_CHAT_BUTTON,

    PERMISSIONS.VIEW_COMMUNICATION_PAGE,
    PERMISSIONS.VIEW_COMMUNICATION_CHAT_WIDGET,

    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE,
    PERMISSIONS.VIEW_AND_EDIT_THERE_IS_NO_BEST_KP_BUTTON,

    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.EDIT_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.EDIT_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.EDIT_COMPANY_CONTACTS,
  ],
  [ROLES.CONTRACTOR]: [
    PERMISSIONS.VIEW_OWN_COMPANY,
    PERMISSIONS.VIEW_MANAGER_INFO,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_CONTRACTOR_BUSINESS_REQUEST_LIST,
    PERMISSIONS.EDIT_SHIPPING_GROUP,
    PERMISSIONS.VIEW_MANAGER_CARD,
    PERMISSIONS.OPEN_CHAT_BY_COMPANY,
    PERMISSIONS.VIEW_REQUEST_NOTIFICATIONS,
    PERMISSIONS.VIEW_OPEN_CHAT_BUTTON,

    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.EDIT_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.EDIT_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_CONTRACTOR_STATUS,

    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,

    PERMISSIONS.CHANGE_ROLE,
    PERMISSIONS.EDIT_COMPANY_CONTACTS,
  ],
  [ROLES.CUSTOMER]: [
    PERMISSIONS.VIEW_OWN_COMPANY,
    PERMISSIONS.VIEW_MANAGER_INFO,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_CUSTOMER_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_MANAGER_CARD,
    PERMISSIONS.OPEN_CHAT_BY_COMPANY,
    PERMISSIONS.VIEW_REQUEST_NOTIFICATIONS,
    PERMISSIONS.VIEW_OPEN_CHAT_BUTTON,

    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    // PERMISSIONS.EDIT_CONTRACTOR_ROLE,

    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,

    PERMISSIONS.CHANGE_ROLE,
    PERMISSIONS.EDIT_COMPANY_CONTACTS,
  ],
  [ROLES.ADMIN]: [
    PERMISSIONS.VIEW_ADMIN_PANEL,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.CREATE_REQUEST,
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.EDIT_COST_LOGISTICS,
    PERMISSIONS.EDIT_SHIPPING_GROUP,
    PERMISSIONS.EDIT_PAYMENT_GROUP,
    PERMISSIONS.SHARE_CONTRACTOR_REQUEST,
    PERMISSIONS.EDIT_WHOLE_PAYMENT,
    PERMISSIONS.EDIT_BUSINESS_REQUEST,
    PERMISSIONS.EDIT_MANAGER_ORDER,
    PERMISSIONS.VIEW_CRM_LINK,

    PERMISSIONS.VIEW_COMMUNICATION_PAGE,
    PERMISSIONS.VIEW_COMMUNICATION_CHAT_WIDGET,

    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_AND_EDIT_APPLICATION_EMAIL_SENDING_TOGGLE,
    PERMISSIONS.VIEW_AND_EDIT_THERE_IS_NO_BEST_KP_BUTTON,

    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.EDIT_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.EDIT_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.EDIT_COMPANY_CONTACTS,
  ],

  // отображение и права для всех разделов, кроме списка заявок, такое же как для роли КАМ и КАМ+
  [ROLES.TECH_EXPERT]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    // PERMISSIONS.VIEW_COMMUNICATION_PAGE,
    // PERMISSIONS.VIEW_MANAGER_BUSINESS_REQUEST_LIST,

    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
  ],
  [ROLES.BACK_OFFICE]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_TEMPLATES,
    PERMISSIONS.VIEW_ALL_COMPANIES,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_PAYMENTS,
    PERMISSIONS.VIEW_SHIPMENTS,
    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.EDIT_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_READY_ACCEPT_ORDERS_STATUS,
  ],
  [ROLES.COMMERCIAL_DIRECTOR]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
  ],
  [ROLES.LOGISTIC]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_REQUEST_NOTIFICATIONS,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
  ],
  [ROLES.PARTNER_RELATIONS_MANAGER]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
  ],

  [ROLES.GENERAL_MANAGER]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_CONTRACTOR_LIST,
    PERMISSIONS.VIEW_CUSTOMER_LIST,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
  ],
  [ROLES.FINANCIAL_MANAGER]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_TEMPLATES,
    PERMISSIONS.VIEW_ALL_COMPANIES,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.VIEW_PAYMENTS,
    PERMISSIONS.VIEW_SHIPMENTS,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.EDIT_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_READY_ACCEPT_ORDERS_STATUS,
  ],
  [ROLES.OFFICE_MANAGER]: [
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_TEMPLATES,
    PERMISSIONS.VIEW_ALL_COMPANIES,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.VIEW_PAYMENTS,
    PERMISSIONS.VIEW_SHIPMENTS,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.EDIT_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_READY_ACCEPT_ORDERS_STATUS,
  ],
  [ROLES.LAWYER]: [
    PERMISSIONS.CLOSE_REQUEST,
    PERMISSIONS.VIEW_FULL_COMPANY_ABOUT_INFO,
    PERMISSIONS.VIEW_CRM_LINK,
    PERMISSIONS.VIEW_FULL_COMPANY_INFO,
    PERMISSIONS.VIEW_TEMPLATES,
    PERMISSIONS.VIEW_ALL_COMPANIES,
    PERMISSIONS.LOGIN_AS_GUEST,
    PERMISSIONS.VIEW_PAYMENTS,
    PERMISSIONS.VIEW_SHIPMENTS,
    PERMISSIONS.VIEW_CLIENT_PANEL,
    PERMISSIONS.VIEW_SPECIFIC_ROLE_BUSINESS_REQUEST_LIST,
    PERMISSIONS.VIEW_APPLICATION_CURRENT_STATUS_DAYS_COUNT,
    PERMISSIONS.VIEW_APPLICATION_STATUS_DESCRIPTION_CARD,
    PERMISSIONS.VIEW_CONTRACTOR_ROLE,
    PERMISSIONS.VIEW_CUSTOMER_ROLE,
    PERMISSIONS.VIEW_COMPANY_COMMENT,
    PERMISSIONS.VIEW_READY_ACCEPT_ORDERS_STATUS,
    PERMISSIONS.VIEW_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_COMPANY_STATUS,
    PERMISSIONS.EDIT_COMPANY_COMMENT,
    PERMISSIONS.EDIT_CONTRACTOR_STATUS,
    PERMISSIONS.EDIT_READY_ACCEPT_ORDERS_STATUS,
  ],
};
