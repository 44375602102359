import { FC } from 'react';
import { Typography, Box, Form, NumberInput, InfoRow } from 'src/components/ui';
import {
  maxLength,
  notNegativeNumber,
  positiveNumber,
  required,
} from 'src/utils/form-rules';
import { FIELD_LENGTH } from 'src/constants';
import { IComponentsProp } from '../../types';

export const Deadlines: FC<IComponentsProp> = ({ viewMode, checkRequired }) => (
  <Box>
    <Typography variant="h3" mb="24px">
      Сроки
    </Typography>
    <Box display="flex" gap="24px">
      <Box flexBasis="100%">
        <Form.Item
          required
          label="Поставка, в к.д."
          viewMode={viewMode}
          name="supplyDuration"
          rules={{
            required: checkRequired ? required : undefined,
            maxLength: maxLength(FIELD_LENGTH.commercialOffer.supplyDuration),
            validate: {
              positiveNumber: positiveNumber(),
            },
          }}>
          <NumberInput decimalScale={0} placeholder="0" />
        </Form.Item>
      </Box>

      <Box flexBasis="100%">
        <Form.Item
          required
          label="Отсрочка платежа, в к.д."
          viewMode={viewMode}
          name="paymentPeriodProlongation"
          rules={{
            maxLength: maxLength(
              FIELD_LENGTH.commercialOffer.paymentPeriodProlongation
            ),
            validate: {
              positiveNumber: notNegativeNumber(),
            },
          }}
          renderView={(value) => (
            <InfoRow
              label="Отсрочка платежа, в к.д."
              value={value === null ? value : String(value)}
              noMargin
            />
          )}>
          <NumberInput decimalScale={0} placeholder="0" />
        </Form.Item>
      </Box>
    </Box>
  </Box>
);
